import axios from "@/plugins/axios";
import { storeErrorHandler } from "@/utils/utils";

// State
const state = {
  loading: {
    fetchFollowers: false,
    fetchFollowings: false,
    follow: null,
    unfollow: null,
    acceptRequest: null,
    rejectRequest: null,
    cancelRequest: null,
  },
};

// Getters
const getters = {
  loading: (state) => state.loading,
};

// Mutations
const mutations = {
  SET_LOADING: (state, { key, value }) => (state.loading[key] = value),
};

// Actions
const actions = {
  async fetchFollowers({ commit }, { id, params = {} }) {
    try {
      commit("SET_LOADING", { key: "fetchFollowers", value: true });

      const { data } = await axios.get("users/followers/" + id, { params });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchFollowers", value: false });
    }
  },

  async fetchFollowings({ commit }, { id, params = {} }) {
    try {
      commit("SET_LOADING", { key: "fetchFollowings", value: true });

      const { data } = await axios.get("users/following/" + id, { params });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchFollowings", value: false });
    }
  },

  async fetchSuggestions({ commit }, params = {}) {
    try {
      commit("SET_LOADING", { key: "fetchSuggestions", value: true });

      const { data } = await axios.get(`me/suggested?users_only=true?count=6`);

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchSuggestions", value: false });
    }
  },

  async follow({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "follow", value: id });

      const { data } = await axios.post("users/follow/" + id);

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "follow", value: null });
    }
  },

  async unfollow({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "unfollow", value: id });

      const { data } = await axios.post("users/unfollow/" + id);

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "unfollow", value: null });
    }
  },

  async acceptRequest({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "acceptRequest", value: id });

      const { data } = await axios.post("users/accept-follow/" + id);

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "acceptRequest", value: null });
    }
  },

  async rejectRequest({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "rejectRequest", value: id });

      const { data } = await axios.post("users/reject-follow/" + id);

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "rejectRequest", value: null });
    }
  },

  async cancelRequest({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "cancelRequest", value: id });

      const { data } = await axios.post("users/cancel-follow/" + id);

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "cancelRequest", value: null });
    }
  },
};

// Export
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
