import axios from "@/plugins/axios";
import { storeErrorHandler } from "@/utils/utils";
import { downloadFile } from "@/utils/helpers";

// State
const state = {
  loading: {
    fetch: false,
    complete: false,
    fetchCompleted: false,
    fetchStats: false,
    fetchFilters: false,
    downloadReport: false,
  },
};

// Getters
const getters = {
  loading: (state) => state.loading,
};

// Mutations
const mutations = {
  SET_LOADING: (state, { key, value }) => (state.loading[key] = value),
};

// Actions
const actions = {
  async fetch({ commit }) {
    try {
      commit("SET_LOADING", { key: "fetch", value: true });

      const res = await axios.get("surveys");

      return res.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetch", value: false });
    }
  },

  async fetchCompleted({ commit }, params = {}) {
    try {
      commit("SET_LOADING", { key: "fetchCompleted", value: true });

      const res = await axios.get("surveys/completed", { params });

      return res.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchCompleted", value: false });
    }
  },

  async fetchStats({ commit }) {
    try {
      commit("SET_LOADING", { key: "fetchStats", value: true });

      const res = await axios.get("statistics/survey");

      return res.data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchStats", value: false });
    }
  },

  async fetchFilters({ commit }) {
    try {
      commit("SET_LOADING", { key: "fetchFilters", value: true });

      const res = await axios.get("surveys/question/filters");

      return res.data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchFilters", value: false });
    }
  },

  async complete({ commit }, { id, payload }) {
    try {
      commit("SET_LOADING", { key: "complete", value: id });

      const res = await axios.post(`surveys/${id}/complete`, payload);

      return res.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "complete", value: false });
    }
  },

  async downloadReport({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "downloadReport", value: id });

      const res = await axios.get(`survey-export/${id}`, {
        responseType: "blob",
        headers: {
          "Content-Disposition": "attachment",
        },
      });

      downloadFile(res.data, `survey-report-${id}.csv`);

      return res.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "downloadReport", value: false });
    }
  },
};

// Export
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
