import axios from "@/plugins/axios";
import { serialize } from "object-to-formdata";
import { storeErrorHandler } from "@/utils/utils";
import eventBus from "@/utils/eventBus";
import { playAudio } from "@/utils/helpers";

// State
const state = {
  loaded: false,
  hasUnread: false,
  search: "",
  active: null,
  conversations: {
    data: [],
    meta: {
      current_page: 0,
    },
  },
  archives: {
    data: [],
    meta: {
      current_page: 0,
    },
  },
  loading: {
    fetchConversation: null,
    fetchArchives: false,
    fetchConversations: false,
    fetchMedia: null,
    updateConversation: null,
    deleteConversation: null,
    leaveConversation: null,
    archiveConversation: null,
    fetchMessages: null,
    start: false,
    sendMessage: false,
    addUser: null,
    removeUser: null,
    toggleMuteUser: null,
    toggleAdmin: null,
  },
};

// Getters
const getters = {
  hasUnread: (state) => {
    if (state.conversations.meta.current_page === 0) {
      return state.hasUnread;
    }

    return (
      state.hasUnread &&
      state.conversations.data.some((c) => c.unread_messages === true)
    );
  },
  active: (state) => state.active,
  conversations: (state) => state.conversations,
  archives: (state) => state.archives,
  loading: (state) => state.loading,
};

// Mutations
const mutations = {
  SET_ACTIVE: (state, value) => (state.active = value),
  SET_CONVERSATIONS: (state, value) => (state.conversations = value),
  SET_ARCHIVES: (state, value) => (state.archives = value),
  SET_CONVERSATIONS_DATA: (state, value) => (state.conversations.data = value),
  SET_LOADING: (state, { key, value }) => (state.loading[key] = value),

  INSERT_CONVERSATION: (state, conversation) => {
    const newData = [conversation, ...state.conversations.data];
    state.conversations.data = newData;
  },

  MOVE_CONVERSATION_TO_TOP: (state, i) => {
    if (i === 0) {
      return;
    }

    const conversation = state.conversations.data[i];
    const newData = [
      conversation,
      ...state.conversations.data.filter((c) => c.id !== conversation.id),
    ];

    state.conversations.data = newData;
  },

  RESET_CONVERSATIONS: (state) => {
    state.conversations = {
      data: [],
      meta: { current_page: 0 },
    };
  },

  RESET_ARCHIVES: (state) => {
    state.archives = {
      data: [],
      meta: { current_page: 0 },
    };
  },
};

// Actions
const actions = {
  async handleIncomingMessage(
    { commit, dispatch, state, rootGetters },
    message
  ) {
    const playSound = () => {
      if (message.user_id !== rootGetters["auth/user"].id) {
        state.hasUnread = true;
        playAudio("notification.mp3");
      }
    };

    const i = state.conversations.data.findIndex(
      (c) => c.id === message.chat_conversation_id
    );

    // If there is no conversation, it means it's message is from new conversation
    if (i === -1) {
      // If active conversaiton matches the new, it means user is sender and
      // the conversation is locally already set to active
      // Else, it means user is receiver, then we need to fetch conversation first
      if (!state.active || state.active.id !== message.chat_conversation_id) {
        const conversation = await dispatch(
          "fetchConversation",
          message.chat_conversation_id
        );
        commit("INSERT_CONVERSATION", conversation);
      } else {
        state.active.last_message = message;
        commit("INSERT_CONVERSATION", state.active);
      }

      playSound();
      return;
    }

    // Conversation already exists, so we're moving it to top
    const conversation = state.conversations.data[i];
    conversation.last_message = message;

    if (conversation.id !== state.active?.id) {
      conversation.unread_messages = true;
    }

    commit("MOVE_CONVERSATION_TO_TOP", i);
    playSound();
  },

  async fetchConversation({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "fetchConversation", value: id });

      const { data } = await axios.get("conversations/" + id);

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchConversation", value: null });
    }
  },

  async fetchConversations({ commit, state }, params = {}) {
    try {
      commit("SET_LOADING", { key: "fetchConversations", value: true });

      params.page = state.conversations.meta.current_page + 1;

      if (state.search) {
        params.search = state.search;
      }

      const { data } = await axios.get("conversations", {
        params,
      });

      if (state.conversations.meta.current_page) {
        state.conversations.data.push(...data.data);
        state.conversations.meta = data.meta;
      } else {
        commit("SET_CONVERSATIONS", data);
        state.loaded = true;

        if (data.data[0] && !state.active) {
          commit("SET_ACTIVE", data.data[0]);
        }
      }

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchConversations", value: false });
    }
  },

  async fetchArchives({ commit }, params = {}) {
    try {
      commit("SET_LOADING", { key: "fetchArchives", value: true });

      params.archive = 1;
      params.page = state.archives.meta.current_page + 1;

      if (state.search) {
        params.search = state.search;
      }

      const { data } = await axios.get("conversations", { params });

      if (state.archives.meta.current_page) {
        state.archives.data.push(...data.data);
        state.archives.meta = data.meta;
      } else {
        commit("SET_ARCHIVES", data);
      }

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchArchives", value: false });
    }
  },

  async fetchMedia({ commit }, { id, params }) {
    try {
      commit("SET_LOADING", { key: "fetchMedia", value: id });

      const { data } = await axios.get("conversations/media/" + id, { params });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchMedia", value: null });
    }
  },

  async updateConversation({ commit }, { id, payload }) {
    try {
      commit("SET_LOADING", { key: "updateConversation", value: id });

      payload._method = "PUT";
      const { data } = await axios.post(
        "conversations/" + id,
        serialize(payload)
      );

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "updateConversation", value: null });
    }
  },

  async deleteConversation({ commit, state }, id) {
    try {
      commit("SET_LOADING", { key: "deleteConversation", value: id });

      const { data } = await axios.delete("conversations/" + id);

      commit(
        "SET_CONVERSATIONS_DATA",
        state.conversations.data.filter((c) => c.id !== id)
      );
      commit("SET_ACTIVE", null);

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "deleteConversation", value: false });
    }
  },

  async leaveConversation({ commit, state, rootState }, id) {
    try {
      commit("SET_LOADING", { key: "leaveConversation", value: id });

      const { data } = await axios.post("participants/remove/" + id, {
        participants: [rootState.auth.user.id],
      });

      commit(
        "SET_CONVERSATIONS_DATA",
        state.conversations.data.filter((c) => c.id !== id)
      );
      commit("SET_ACTIVE", null);

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "leaveConversation", value: false });
    }
  },

  async archiveConversation({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "archiveConversation", value: id });

      const { data } = await axios.post("conversations/archive/" + id, {
        archive: 1,
      });

      commit(
        "SET_CONVERSATIONS_DATA",
        state.conversations.data.filter((c) => c.id !== id)
      );
      commit("SET_ACTIVE", null);

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "archiveConversation", value: false });
    }
  },

  async fetchMessages({ commit }, params = {}) {
    try {
      commit("SET_LOADING", { key: "fetchMessages", value: true });

      params.per_page = 30;

      const { data } = await axios.get("messages", {
        params,
      });

      data.data.reverse();

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchMessages", value: false });
    }
  },

  async checkUnread({ commit, state }) {
    try {
      commit("SET_LOADING", { key: "checkUnread", value: true });

      const { data } = await axios.get("conversations/unread-check");
      state.hasUnread = data.data.unread_messages;

      return data.data.unread_messages;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "checkUnread", value: false });
    }
  },

  async start({ commit }, payload) {
    try {
      commit("SET_LOADING", { key: "start", value: true });

      const { data } = await axios.post("conversations", serialize(payload));

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "start", value: false });
    }
  },

  async sendMessage({ commit }, payload) {
    try {
      commit("SET_LOADING", { key: "sendMessage", value: true });

      const { data } = await axios.post("messages", serialize(payload));

      data.data.chat_conversation_id = payload.chat_conversation_id;
      eventBus.$emit("chat:sent", data.data);

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "sendMessage", value: false });
    }
  },

  async unsendMessage({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "unsendMessage", value: id });

      const { data } = await axios.post("messages/unsend/" + id);

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "unsendMessage", value: null });
    }
  },

  async addUser({ commit }, { id, payload }) {
    try {
      commit("SET_LOADING", { key: "addUser", value: id });

      const { data } = await axios.post("participants/add/" + id, payload);

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "addUser", value: null });
    }
  },

  async removeUser({ commit }, { id, payload }) {
    try {
      commit("SET_LOADING", { key: "removeUser", value: id });

      const { data } = await axios.post("participants/remove/" + id, payload);

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "removeUser", value: null });
    }
  },

  async toggleMuteUser({ commit }, { id, participant }) {
    try {
      commit("SET_LOADING", { key: "toggleMuteUser", value: id });

      const action = participant.is_muted
        ? "participants/unmute"
        : "participants/mute";

      const { data } = await axios.post(
        `${action}/${id}/${participant.user.id}`
      );

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "toggleMuteUser", value: null });
    }
  },

  async toggleAdmin({ commit }, { id, participant }) {
    try {
      commit("SET_LOADING", { key: "toggleAdmin", value: id });

      const action = participant.is_chat_admin
        ? "participants/remove-admin"
        : "participants/make-admin";

      const { data } = await axios.post(
        `${action}/${id}/${participant.user.id}`
      );

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "toggleAdmin", value: null });
    }
  },
};

// Export
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
