import axios from "@/plugins/axios";
import { storeErrorHandler } from "@/utils/utils";

// State
const state = {
  loading: {
    fetch: false,
    fetchStats: null,
    search: false,
    fetchById: null,
    fetchImages: false,
    delete: null,
    block: null,
    unblock: null,
  },
};

// Getters
const getters = {
  loading: (state) => state.loading,
};

// Mutations
const mutations = {
  SET_LOADING: (state, { key, value }) => (state.loading[key] = value),
};

// Actions
const actions = {
  async fetch({ commit }, params) {
    try {
      commit("SET_LOADING", { key: "fetch", value: true });

      const { data } = await axios.get("users", { params });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetch", value: false });
    }
  },

  async fetchStats({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "fetchStats", value: id });

      const { data } = await axios.get("user-statistics/" + id);

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchStats", value: null });
    }
  },

  async search({ commit }, params) {
    try {
      commit("SET_LOADING", { key: "search", value: true });

      const { data } = await axios.get("users/search", { params });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "search", value: false });
    }
  },

  async fetchById({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "fetchById", value: id });

      let resp;

      if (Number(id)) {
        resp = await axios.get("users/" + id);
      } else {
        resp = await axios.get("users/by-username/" + id);
      }

      return resp.data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchById", value: null });
    }
  },

  async fetchImages({ commit }, { id, params = {} }) {
    try {
      commit("SET_LOADING", { key: "fetchImages", value: true });

      const { data } = await axios.get("users/images/" + id, { params });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchImages", value: false });
    }
  },

  async delete({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "delete", value: id });

      const res = await axios.delete("users/" + id);

      return res.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "delete", value: null });
    }
  },

  async block({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "block", value: id });

      const res = await axios.post("users/block/" + id);

      return res.data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "block", value: null });
    }
  },

  async unblock({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "unblock", value: id });

      const res = await axios.post("users/unblock/" + id);

      return res.data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "unblock", value: null });
    }
  },
};

// Export
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
