import axios from "@/plugins/axios";
import config from "@/config/config";
import { storeErrorHandler } from "@/utils/utils";

// State
const state = {
  loading: {
    fetchSuggestions: false,
  },
};

// Getters
const getters = {
  loading: (state) => state.loading,
};

// Mutations
const mutations = {
  SET_LOADING: (state, { key, value }) => (state.loading[key] = value),
};

// Actions
const actions = {
  async fetchSuggestions({ commit }, params = {}) {
    try {
      commit("SET_LOADING", { key: "fetchSuggestions", value: true });

      const count = config.feed.suggestionsNumber;
      const { data } = await axios.get(`me/suggested?count=${count}`, {
        params,
      });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchSuggestions", value: false });
    }
  },
};

// Export
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
