import i18n from "@/plugins/i18n";
import config from "@/config/config";

export default function (str) {
  if (!str || str.length < config.username.minLength) {
    return i18n.t("rules.username.short", { n: config.username.minLength });
  }

  if (str.length > config.username.maxLength) {
    return i18n.t("rules.username.long", { n: config.username.maxLength });
  }

  if (false === /^[a-zA-Z0-9_]+$/.test(str)) {
    return i18n.t("rules.username.allowedChars");
  }

  if (str.search("__") !== -1) {
    return i18n.t("rules.username.doubleUnderscore");
  }

  if (str[0] === "_") {
    return i18n.t("rules.username.start");
  }

  if (str[str.length - 1] === "_") {
    return i18n.t("rules.username.end");
  }

  if (str.search(/[a-zA-Z]/) === -1) {
    return i18n.t("rules.username.mustContainLetter");
  }

  const wordRegex = new RegExp(`${config.username.blacklist.join("|")}`, "i");
  if (str.search(wordRegex) !== -1) {
    return i18n.t("rules.username.invalid");
  }

  return true;
}
