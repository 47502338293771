import authMiddleware from "../middleware/auth";
import nonProdMiddleware from "../middleware/nonprod";

export default {
  path: "/",
  alias: "/app",
  meta: {
    middleware: [authMiddleware],
  },
  component: () =>
    import(
      /* webpackChunkName: "AppLayout" */
      "@/components/layouts/app/AppLayout"
    ),
  children: [
    {
      path: "feed",
      alias: "",
      name: "app",
      component: () =>
        import(
          /* webpackChunkName: "FeedPage" */
          "@/pages/app/FeedPage"
        ),
    },
    {
      path: "user/:id",
      name: "app.user",
      component: () =>
        import(
          /* webpackChunkName: "UserPage" */
          "@/pages/app/UserPage"
        ),
    },
    {
      path: "group/:id",
      name: "app.group",
      component: () =>
        import(
          /* webpackChunkName: "GroupPage" */
          "@/pages/app/GroupPage"
        ),
    },
    {
      path: "follows",
      component: () =>
        import(
          /* webpackChunkName: "FollowsPage" */
          "@/pages/app/FollowsPage"
        ),
      children: [
        {
          path: "",
          alias: "followers",
          name: "app.follows.followers",
          meta: { type: "followers" },
        },
        {
          path: "following",
          name: "app.follows.following",
          meta: { type: "following" },
        },
        {
          path: "suggested",
          name: "app.follows.suggested",
          meta: { type: "suggested" },
        },
      ],
    },
    {
      path: "groups",
      component: () =>
        import(
          /* webpackChunkName: "GroupsPage" */
          "@/pages/app/GroupsPage"
        ),
      children: [
        {
          path: "",
          name: "app.groups",
          meta: { type: "groups" },
        },
        {
          path: "suggested",
          name: "app.groups.suggested",
          meta: { type: "suggested" },
        },
      ],
    },
    {
      path: "settings",
      component: () =>
        import(
          /* webpackChunkName: "SettingsPage" */
          "@/pages/app/SettingsPage"
        ),
      children: [
        {
          path: "",
          name: "app.settings",
          meta: { tab: "SettingsGeneral" },
        },
        {
          path: "preferences",
          name: "app.settings.preferences",
          meta: { tab: "SettingsPreferences" },
        },
        {
          path: "privacy",
          name: "app.settings.privacy",
          meta: { tab: "SettingsPrivacy" },
        },
      ],
    },
    {
      path: "notifications",
      name: "app.notifications",
      component: () =>
        import(
          /* webpackChunkName: "NotificationsPage" */
          "@/pages/app/NotificationsPage"
        ),
    },
    {
      path: "chat",
      name: "app.chat",
      component: () =>
        import(
          /* webpackChunkName: "ChatPage" */
          "@/pages/app/ChatPage"
        ),
    },
    {
      path: "post/:id",
      name: "app.post",
      component: () =>
        import(
          /* webpackChunkName: "PostPage" */
          "@/pages/app/PostPage"
        ),
    },
    {
      path: "search/:index?",
      name: "app.search",
      component: () =>
        import(
          /* webpackChunkName: "SearchPage" */
          "@/pages/app/SearchPage"
        ),
    },
    {
      path: "contacts",
      name: "app.contacts",
      component: () =>
        import(
          /* webpackChunkName: "ContactsPage" */
          "@/pages/app/ContactsPage"
        ),
    },
    {
      path: "wallet",
      name: "app.wallet",
      meta: { middleware: [nonProdMiddleware] },
      component: () =>
        import(
          /* webpackChunkName: "WalletPage" */
          "@/pages/app/WalletPage"
        ),
    },
  ],
};
