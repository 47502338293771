<template>
  <v-input
    v-model="model"
    v-on="$listeners"
    :rules="$attrs.rules || []"
    :label="$attrs.label"
    class="chip-select"
    :hide-details="$attrs['hide-details']"
  >
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
      class="mb-7 mt-4"
    />

    <v-chip-group v-else v-model="model" v-bind="$attrs" color="primary" column>
      <v-chip
        v-for="(item, i) in items"
        :key="i"
        :value="itemValue ? item[itemValue] : item"
        :color="color"
      >
        {{ itemText ? item[itemText] : item }}
      </v-chip>
    </v-chip-group>
  </v-input>
</template>

<script>
export default {
  name: "ChipSelect",

  props: {
    value: {
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      required: false,
    },
    itemValue: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "transparent",
    },
  },

  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },

  watch: {
    value: "formatValue",
  },

  created() {
    this.formatValue();
  },

  methods: {
    formatValue() {
      const val = this.value;
      if (Array.isArray(val) && val.length && typeof val[0] == "object") {
        this.model = val.map((i) => i.id);
      }
    },
  },
};
</script>

<style lang="scss">
.chip-select {
  .v-input__slot {
    flex-direction: column;
    align-items: start;
  }

  .v-label {
    width: 100%;
  }

  .v-slide-group__content {
    padding: 0 !important;
  }
}
</style>

<style lang="scss" scoped>
.v-chip-group {
  .v-chip:not(.v-chip--active) {
    &:not(.transparent) {
      border: 1px solid transparent !important;
    }
    &.transparent {
      border: 1px solid $gray !important;
    }

    &::before {
      opacity: 0 !important;
    }
  }

  .v-chip--active {
    background-color: var(--v-primary-base) !important;
    border: 1px solid transparent !important;
    color: white !important;

    &::before {
      opacity: 0 !important;
    }
  }
}
</style>
