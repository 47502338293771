import axios from "axios";
import router from "@/router";
import store from "@/store";
import { env, dayjs } from "@/utils/utils";
import eventBus from "@/utils/eventBus";

const instance = axios.create({
  baseURL: env("API_URL"),
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

let refreshTokenPromise = null;

// Request interceptor for API calls
instance.interceptors.request.use(
  (config) => {
    store.state.app.lastPing = dayjs();
    setAuthorizationHeader(config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for API calls
instance.interceptors.response.use(
  (response) => {
    if (typeof response.data === "object") {
      response.data.success = true;
    }
    return response;
  },
  async (error) => {
    const isLogin = router.currentRoute.name === "auth.login";

    // 401
    if (error.response.status === 401 && !isLogin) {
      const originalRequest = error.config;

      if (!originalRequest._retry) {
        originalRequest._retry = true;

        if (!refreshTokenPromise) {
          refreshTokenPromise = refreshToken().finally(() => {
            refreshTokenPromise = null;
          });
        }

        return refreshTokenPromise
          .then(() => {
            setAuthorizationHeader(originalRequest);
            return instance(originalRequest);
          })
          .catch(() => {
            logout();
            redirectToLogin();
          });
      }

      logout();
      redirectToLogin();
    }

    error.response.data.success = false;
    return Promise.reject(error);
  }
);

function setAuthorizationHeader(config) {
  // Be aware: Paypal redirect returns "token" query parameter
  const urlToken = new URLSearchParams(window.location.search).get("api_token");

  if (urlToken) {
    localStorage.setItem("token", urlToken);
  }

  const token = localStorage.getItem("token");
  config.headers.Authorization = `Bearer ${token}`;
}

function refreshToken() {
  const newAxiosInstance = axios.create({
    baseURL: env("API_URL"),
    withCredentials: true,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  });

  return newAxiosInstance.post("auth/refresh").then((res) => {
    localStorage.setItem("token", res.data.access_token);
  });
}

function logout() {
  const user = Object.assign({}, store.getters["auth/user"]);
  store.commit("auth/SET_USER", null);
  localStorage.removeItem("token");
  eventBus.$emit("auth:logout", user);
}

function redirectToLogin() {
  if (router.currentRoute.name === "auth.login") {
    return;
  }

  const redirect = router.currentRoute.path + window.location.search;

  return router.replace({
    name: "auth.login",
    query: { redirect },
  });
}

export default instance;
