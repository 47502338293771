import axios from "@/plugins/axios";
import { storeErrorHandler } from "@/utils/utils";

// State
const state = {
  loading: {
    global: false,
    index: false,
  },
};

// Getters
const getters = {
  loading: (state) => state.loading,
};

// Mutations
const mutations = {
  SET_LOADING(state, { key, value }) {
    state.loading[key] = value;
  },
};

// Actions
const actions = {
  async global({ commit }, params) {
    try {
      commit("SET_LOADING", { key: "global", value: true });

      const { data } = await axios.get("search", { params });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "global", value: false });
    }
  },

  async index({ commit }, { index, params }) {
    try {
      commit("SET_LOADING", { key: "index", value: true });

      const { data } = await axios.get("search/" + index, { params });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "index", value: false });
    }
  },
};

// Export
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
