import store from "@/store";

export default function ({ to, next }) {
  const user = store.getters["auth/user"];

  if (!user) {
    const redirect = to.path + window.location.search;

    return next({
      name: "auth.login",
      query: { redirect },
    });
  }

  return next();
}
