import dayjs from "@/plugins/dayjs";
import i18n from "@/plugins/i18n";
import vuetify from "@/plugins/vuetify";

export default function (locale) {
  if (!locale || i18n.locale === locale) {
    return;
  }

  if (!i18n.availableLocales.includes(locale)) {
    return console.log(`Locale '${locale}' not supported.`);
  }

  // i18n
  i18n.locale = locale;

  // dayjs
  require(`dayjs/locale/${locale}`);
  dayjs.locale(locale);

  // Vuetify
  vuetify.framework.lang.current = locale;
}
