import axios from "@/plugins/axios";
import { storeErrorHandler } from "@/utils/utils";
import { serialize } from "object-to-formdata";

// State
const state = {
  loading: {
    fetchById: null,
    fetchJoined: false,
    fetchMembers: false,
    fetchMembersAsAdmin: false,
    fetchAdmins: false,
    fetchImages: false,
    create: false,
    update: false,
    delete: null,
    join: null,
    cancelJoin: null,
    leave: null,
    fetchJoinRequests: false,
    acceptJoinRequest: null,
    declineJoinRequest: null,
    removeMember: null,
    addAdmin: null,
    removeAdmin: null,
    inviteUsers: null,
    acceptInvite: null,
    declineInvite: null,
  },
};

// Getters
const getters = {
  loading: (state) => state.loading,
};

// Mutations
const mutations = {
  SET_LOADING: (state, { key, value }) => (state.loading[key] = value),
};

// Actions
const actions = {
  async fetchById({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "fetchById", value: id });

      const { data } = await axios.get("groups/" + id);

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchById", value: null });
    }
  },

  async fetchJoined({ commit }, params = {}) {
    try {
      commit("SET_LOADING", { key: "fetchJoined", value: true });

      const { data } = await axios.get("groups/joined", { params });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchJoined", value: false });
    }
  },

  async fetchSuggestions({ commit }, params = {}) {
    try {
      commit("SET_LOADING", { key: "fetchSuggestions", value: true });

      const { data } = await axios.get(`me/suggested`, {
        params: { groups_only: "true", ...params },
      });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchSuggestions", value: false });
    }
  },

  async fetchMembers({ commit }, { id, params = {} }) {
    try {
      commit("SET_LOADING", { key: "fetchMembers", value: true });

      const { data } = await axios.get("groups/members/" + id, { params });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchMembers", value: false });
    }
  },

  async fetchMembersAsAdmin({ commit }, { id, params = {} }) {
    try {
      commit("SET_LOADING", { key: "fetchMembersAsAdmin", value: true });

      const { data } = await axios.get("groups/group-admin/members/" + id, {
        params,
      });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchMembersAsAdmin", value: false });
    }
  },

  async fetchAdmins({ commit }, { id, params = {} }) {
    try {
      commit("SET_LOADING", { key: "fetchAdmins", value: true });

      const { data } = await axios.get("groups/admins/" + id, { params });
      data.data.forEach((user) => (user.is_group_admin = true));

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchAdmins", value: false });
    }
  },

  async fetchImages({ commit }, { id, params = {} }) {
    try {
      commit("SET_LOADING", { key: "fetchImages", value: true });

      const { data } = await axios.get("groups/images/" + id, { params });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchImages", value: false });
    }
  },

  async create({ commit }, payload) {
    try {
      commit("SET_LOADING", { key: "create", value: true });

      const { data } = await axios.post(
        "groups",
        serialize(payload, { booleansAsIntegers: true })
      );

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "create", value: false });
    }
  },

  async update({ commit }, { id, payload }) {
    try {
      commit("SET_LOADING", { key: "update", value: true });

      payload._method = "PUT";
      const { data } = await axios.post("groups/" + id, serialize(payload));

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "update", value: false });
    }
  },

  async delete({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "delete", value: id });

      const { data } = await axios.delete("groups/" + id);

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "delete", value: false });
    }
  },

  async join({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "join", value: id });

      const { data } = await axios.put("groups/join-group/" + id);

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "join", value: null });
    }
  },

  async cancelJoin({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "cancelJoin", value: id });

      const { data } = await axios.put("groups/cancel-pending-request/" + id);

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "cancelJoin", value: null });
    }
  },

  async leave({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "leave", value: id });

      const { data } = await axios.put("groups/leave-group/" + id);

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "leave", value: null });
    }
  },

  async fetchJoinRequests({ commit }, { id, params = {} }) {
    try {
      commit("SET_LOADING", { key: "fetchJoinRequests", value: true });

      const { data } = await axios.get("groups/pending-requests/" + id, {
        params,
      });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchJoinRequests", value: false });
    }
  },

  async acceptJoinRequest({ commit }, { groupId, userId }) {
    try {
      commit("SET_LOADING", { key: "acceptJoinRequest", value: userId });

      const { data } = await axios.put("groups/accept-request/" + groupId, {
        user_id: userId,
      });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "acceptJoinRequest", value: null });
    }
  },

  async declineJoinRequest({ commit }, { groupId, userId }) {
    try {
      commit("SET_LOADING", { key: "declineJoinRequest", value: userId });

      const { data } = await axios.put("groups/decline-request/" + groupId, {
        user_id: userId,
      });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "declineJoinRequest", value: null });
    }
  },

  async removeMember({ commit }, { groupId, userId }) {
    try {
      commit("SET_LOADING", { key: "removeMember", value: userId });

      const { data } = await axios.put(
        "groups/remove-group-member/" + groupId,
        { user_id: userId }
      );

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "removeMember", value: null });
    }
  },

  async addAdmin({ commit }, { groupId, userId }) {
    try {
      commit("SET_LOADING", { key: "addAdmin", value: userId });

      const { data } = await axios.put("groups/add-group-admin/" + groupId, {
        user_id: userId,
      });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "addAdmin", value: null });
    }
  },

  async removeAdmin({ commit }, { groupId, userId }) {
    try {
      commit("SET_LOADING", { key: "removeAdmin", value: userId });

      const { data } = await axios.put("groups/remove-group-admin/" + groupId, {
        user_id: userId,
      });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "removeAdmin", value: null });
    }
  },

  async inviteUsers({ commit }, { groupId, payload }) {
    try {
      commit("SET_LOADING", { key: "inviteUsers", value: groupId });

      const { data } = await axios.put(
        "groups/invite-members/" + groupId,
        payload
      );

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "inviteUsers", value: null });
    }
  },

  async acceptInvite({ commit }, groupId) {
    try {
      commit("SET_LOADING", { key: "acceptInvite", value: groupId });

      const { data } = await axios.put("groups/accept-invitation/" + groupId);

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "acceptInvite", value: null });
    }
  },

  async declineInvite({ commit }, groupId) {
    try {
      commit("SET_LOADING", { key: "declineInvite", value: groupId });

      const { data } = await axios.put("groups/decline-invitation/" + groupId);

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "declineInvite", value: null });
    }
  },
};

// Export
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
