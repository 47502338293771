export default {
  common: {
    add: "Add",
    admin: "Admin",
    accept: "Accept",
    all: "All",
    attachment: "Attachment",
    back: "Back",
    block: "Block",
    cancel: "Cancel",
    confirm: "Confirm",
    confirmText: "Are you sure you want to perform this action?",
    content: "Content",
    copy: "Copy",
    copyLink: "Copy link",
    country: "Country",
    create: "Create",
    daily: "Daily",
    dashboard: "Dashboard",
    date: "Date",
    decline: "Decline",
    delete: "Delete",
    description: "Description",
    details: "Details",
    edit: "Edit",
    edited: "Edited",
    editedAt: "Edited at {date}",
    email: "Email",
    endsOn: "Ends on {date}",
    error: "Error",
    feed: "Feed",
    file: "File",
    filters: "Filters",
    firstName: "First name",
    gif: "GIF",
    group: "Group",
    groups: "Groups",
    home: "Home",
    image: "Image",
    invite: "Invite",
    language: "Language",
    lastName: "Last name",
    linkCopied: "Link copied.",
    loading: "Loading...",
    loadMore: "Load more",
    maxUploadSize: "Max. file upload size is {n}.",
    menu: "Menu",
    messages: "Messages",
    monthly: "Monthly",
    mute: "Mute",
    name: "Name",
    next: "Next",
    noDataAvailable: "No data available.",
    noDataFound: "Sorry, no data found.",
    notifications: "Notifications",
    noResults: "No results.",
    noResultsLong: "Sorry, no results found.",
    off: "Off",
    or: "or",
    on: "On",
    options: "Options",
    pageNotFound: "Page not found",
    pending: "Pending",
    private: "Private",
    public: "Public",
    publish: "Publish",
    refresh: "Refresh",
    remove: "Remove",
    reset: "Reset",
    requests: "Requests",
    save: "Save",
    search: "Search",
    searchMore: "Search more",
    send: "Send",
    showAll: "Show all",
    showMore: "Show more",
    settings: "Settings",
    sinceDate: "Since {date}",
    start: "Start",
    status: "Status",
    submit: "Submit",
    success: "Success",
    suggestions: "Suggestions",
    tapToChange: "Tap to change",
    type: "Type",
    unblock: "Unblock",
    update: "Update",
    user: "User",
    users: "Users",
    username: "Username",
    unmute: "Unmute",
    verify: "Verify",
    video: "Video",
    wallet: "Wallet",
    yearly: "Yearly",
  },

  calendar: {
    calendar: "Calendar",
    noEventsForDate: "There are no events for the selected date.",
  },

  contacts: {
    book: "Contacts book",
    edit: "Edit contact",
    fullName: "Full name",
    phone: "Phone",
    company: "Company",
    companyLink: "Company link",
    birthday: "Birthday",
    address: "Address",
    city: "City",
    country: "Country",
  },

  chat: {
    inputPlaceholder: "Enter your message...",
    you: "You",
    start: "Start chat",
    to: "Send message to",
    groupName: "Group name",
    delete: "Delete conversation",
    leave: "Leave conversation",
    archive: "Archive conversation",
    archived: "Archived conversations",
    noChats: "You don't have active chats.",
    noArchivedChats: "You don't have archived chats.",
    addUser: "Add user to chat",
    mutedAlert: "You are muted from this chat.",
    setAdmin: "Set admin",
    unsetAdmin: "Unset admin",
    unsend: "Unsend",
    unsent: "Message unsent",
    sharedMedia: "Shared media",
  },

  clubs: {
    club: "Club",
    favClub: "Favourite club",
  },

  dashboard: {
    totalUsers: "Total users",
    loggedIn: "Logged in users",
    inactiveUsers: "Inactive users",
    deactivatedUsers: "Deactivated users",
    averageOnline: "Average online time",
    newUsers: "New users",
    countriesChart: "Most registered countries",
    favouriteClubs: "Favourite clubs",
    totalPosts: "Total posts",
    totalReshares: "Total reshares",
    totalGroupPosts: "Group posts",
    totalFeedPosts: "Feed posts",
    newPosts: "New posts",
    totalGroups: "Total groups",
    totalPublicGroups: "Public groups",
    totalPrivateGroups: "Private groups",
    numberOfLogins: "Number of logins",
    numberOfPosts: "Number of posts",
    postsInFeed: "Posts in feed",
    postsInGroup: "Posts in group",
    joinedGroups: "Joined groups",
    administratedGroups: "Administrated groups",
  },

  feed: {
    suggested: "Suggested",
    empty: "Nothing here :(",
  },

  forgotPassword: {
    title: "Reset your password",
    subtitle: "Link with instruction will be sent to your email",
    success: {
      heading: "Check your email",
      text1: "You will recieve and email with a reset password link.",
      text2: "Check your inbox and follow the reset password link.",
    },
  },

  groups: {
    createNew: "Create group",
    delete: "Delete group",
    deleted: "Group deleted.",
    edit: "Edit group",
    noGroups: "You did not join any group.",
    noPosts: "This group does not have any posts yet.",
    join: "Join",
    joined: "Joined",
    leave: "Leave",
    pendingRequest: "Pending",
    cancelRequest: "Cancel request",
    nMembers: "No members | One member | {count} members",
    publicFeed: "Public feed",
    privateFeed: "Private feed",
    interests: "Group interests",
    manageMembers: "Manage members",
    memberRequests: "Member requests",
    members: "Members",
    admins: "Administrators",
    inviteUsers: "Invite users",
    privateGroupAlert:
      "This group is private. You must become a member to see posts or who's in the group.",
    deleteText:
      "Are you sure you want to delete group {name}?\nThis action is permanent and cannot be undone.",
    pendingInvitations: "Pending invitations",
    noInvitations: "No pending invitations.",
    makeAdmin: "Make admin",
    removeAdmin: "Remove admin",
    removeAdminConfirm:
      "Are you sure you want to remove {username} from the group?",
  },

  hashtags: {
    hashtag: "Hashtag",
    hashtags: "Hashtags",
    trending: "Trending hashtags",
    mentions: "Mentions",
    nMentions: "{n} mentions",
    totalHashtags: "Total hashtags",
    trendingHashtags: "Trending hashtags",
    hotHashtags: "Hot hashtags",
    lastUsed: "Last used",
    trendingScore: "Trending score",
  },

  login: {
    title: "Welcome back",
    subtitle: "Log in to your account",
    forgotPassword: "Forgot your password?",
    login: "Log in",
    loginFailed: "Invalid email or password.",
    loginGoogle: "Login with Google",
    loginFacebook: "Login with Facebook",
    logout: "Logout",
    noAccount: "Don't have account yet?",
    password: "Password",
    passwordConfirm: "Confirm password",
    returnToLogin: "Return to login",
    signUp: "Sign up",
    welcome: "Welcome.",

    notVerified: {
      title: "Email not verified",
      text1:
        "We've sent account verification link to your email address. Please click on the link given in email to verify your account.",
      text2:
        "If you didn't receive email or your link expired, click on the button below to resend the link.",
      button: "Resend link",
    },

    twoFA: {
      title: "Two-factor authentication",
      subtitle:
        "Please enter the two-factor authentication (2FA) code that is sent to your email.",
    },
  },

  maintenance: {
    title: "We'll be back soon!",
    text: "Sorry for the inconvenience but we're performing some maintenance at the moment. We'll be back online shortly!",
  },

  newsletter: {
    title: "SUBSCRIBE TO OUR NEWSLETTER",
    text: "Be first to receive updates and special offers!",
    acceptButton: "Subscribe now",
    declineButton: "No, thanks",
    thanks: "Thanks for subscribing to our newsletter!",
  },

  notifications: {
    all: "All notifications",
    unread: "Unread",
    markAllAsRead: "Mark all as read",
    markAsRead: "Mark as read",
    noNotifications: "You don't have any notifications.",
    noUnread: "You don't have any unread notifications.",
    newNotificationAlert: "You have new notification.",
    notAvailable: "Sorry, the item is no longer available.",

    titles: {
      CommentLike: "New like on comment",
      CommentUserMention: "New comment mention",
      GroupAccept: "Join request accepted",
      GroupDecline: "Join request declined",
      GroupInvite: "New group invite",
      GroupRequest: "New group request",
      GroupNewPost: "New group post",
      PostComment: "New comment on post",
      PostLike: "New like on post",
      PostShare: "New share on post",
      PostUserMention: "New post mention",
      UserFollow: "New follower",
      UserFollowRequest: "New follow request",
      UserFollowRequestAccept: "Follow request accepted",
    },

    messages: {
      CommentLike: "{user} liked your comment.",
      CommentUserMention: "{user} mentioned you in a comment.",
      GroupAccept: "You have been accepted to the group {group}",
      GroupDecline: "Your request to join the group {group} has been denied.",
      GroupInvite: "{user} invited you to the group {group}.",
      GroupRequest: "{user} requested to join the group {group}.",
      GroupNewPost: "{group} has new post.",
      PostComment: "{user} commented on your post.",
      PostLike: "{user} liked your post.",
      PostShare: "{user} shared your post.",
      UserFollow: "{user} started following you.",
      PostUserMention: "{user} mentioned you in a post.",
      UserFollowRequest: "{user} sent you a follow request.",
      UserFollowRequestAccept: "{user} has accepted your follow request.",
    },
  },

  polls: {
    poll: "Poll",
    title: "Poll title",
    remove: "Remove poll",
    option: "Poll option",
    addOption: "Add option",
    duration: "Poll duration",
    days: "Days",
    hours: "Hours",
    minutes: "Minutes",
    nVotes: "{n} votes",
    timeLeft: "{time} left",
    thanks: "Thank you for voting!",
    ended: "The poll has ended.",
    votes: "Votes",
    resultsVisibility: "Who can see results?",
    resultsPublic: "Everyone",
    resultsHidden: "Just me",
    resultsHiddenWhileActive: "Everyone after poll ends",
    resultsHiddenNote:
      "The results of this poll are not available to the public.",
    resultsHiddenWhileActiveNote:
      "The results of this poll will be visible after the poll ends.",
  },

  posts: {
    post: "Post",
    posts: "Posts",
    createPlaceholder: "Create a post...",
    likes: "Likes",
    nLikes: "No likes | One like | {count} likes",
    nComments: "No comments | One comment | {count} comments",
    nReplies: "No replies | One reply | {count} replies",
    nShares: "No shares | One share | {count} shares",
    nViews: "No views | One view | {count} views",
    writeComment: "Comment on post...",
    writeReplyTo: "Reply to {username}...",
    loadMoreComments: "Load more comments",
    sharePost: "Share post",
    editPost: "Edit post",
    deleteText: "Are you sure you want to delete this post?",
    attachmentsInvalid: "Please choose either 1 video or up to 4 photos.",
    cancelEditHint: "Press ESC to cancel editing",
  },

  settings: {
    general: "Account",
    privacy: "Security & Privacy",
    preferences: "Preferences",
    privateProfile: "Private profile",
    downloadUserData: "Download user data",
    twoFactor: "Enable two-factor authentication",
    updated: "Settings updated.",
    notificationsOff: "Turn off notifications",
    notificationsSoundOff: "Disable notification sounds",
    newsletter: "Subscribe to Newsletter",
  },

  register: {
    title: "Create account",
    subtitle: "Enter user details",
    haveAccount: "Already have an account?",
    registerGoogle: "Register with Google",
    registerFacebook: "Register with Facebook",
    checkingUsername: "Checking username...",
    usernameTaken: "Username is taken.",
    completeRegistration: "Complete registration",
    completedRegistrationAlert:
      "You have successfully completed the registration and can now log in!",
    disabled: "Registration is currently disabled. Please try again later.",
    termsAndConditions:
      "By registering, you agree with our Terms & Conditions and Privacy and Cookie Policy.",

    onboard: {
      title: "Tell us more about yourself",
      subtitle: "Enter details",
      favouriteFootballClub: "Favourite football club",
      country: "Country",
    },

    success: {
      title: "Please verify your email",
      text1: "Thank you for registering.",
      text2: "Confirmation link has been sent to your email.",
    },
  },

  reports: {
    report: "Report",
    reports: "Reports",
    reporter: "Reporter",
    why: "Why are you reporting this?",
    submitted: "Report submitted.",
    alreadyReported: "You have already reported this.",
    totalReports: "Total reports",
    userReports: "User reports",
    groupReports: "Group reports",
    postReports: "Post reports",
    commentReports: "Comment reports",
    viewItem: "View item",

    types: {
      spam: {
        title: "Spam",
        description:
          "Sharing irrelevant or repeated content to boost visibility or for monetary gain",
      },
      scam: {
        title: "Fraud or scam",
        description:
          "Deceiving others to obtain money or access private information",
      },
      fake: {
        title: "Fake account",
        description: "Inaccurate or misleading representation",
      },
      offensive_language: {
        title: "Offensive language",
        description: "Hateful, degrading, or inflammatory speech",
      },
      violence: {
        title: "Violence",
        description: "Violence or physical harm",
      },
      adult_content: {
        title: "Adult content",
        description: "Nudity or sexual content",
      },
    },
  },

  resetPassword: {
    resetPassword: "Reset password",
    title: "Set new password",
    subtitle: "Write down your new password",
    success: {
      heading: "Password updated",
      text: "You have successfully changed your password. Use your new password to login.",
    },
  },

  rules: {
    creditCard: "Invalid credit card number.",
    cvv: "Invalid card security code.",
    date: "Date must be in DD.MM.YYYY format and valid.",
    duration: "Duration must be in HH:MM:SS format.",
    email: "Must be a valid e-mail address.",
    expirationDate: "Invalid expiration date.",
    number: {
      greater: "Must be greater than {n}{suffix}.",
      less: "Must be less than {n}{suffix}.",
      min: "Must be a minimum of {n}{suffix}.",
      max: "Must be a maximum of {n}{suffix}.",
    },
    password:
      "At least 8 characters, must contain a number and a special character.",
    phone: "Must be a valid phone number.",
    required: "This field is required.",
    time24: "Time must be in hh:mm format.",
    same: "Must be the same as {field}.",
    selection: {
      max: "Maximum {n} selections.",
      min: "Minimum {n} selections.",
    },
    string: {
      size: "Must be {n} characters long.",
      max: "Maximum {n} characters.",
      min: "Minimum {n} characters.",
    },
    url: "Must be a valid URL.",
    year: "Must be a valid year.",
    username: {
      short: `Username cannot be shorter than {n} characters.`,
      long: `Username cannot be longer than {n} characters`,
      allowedChars:
        "Username can only contain alphanumeric characters and underscores.",
      start: "Username must start with a letter.",
      end: "Username must end with a letter or number",
      mustContainLetter: "Username must contain letter.",
      invalid: "Username invalid.",
      doubleUnderscore: "Username cannot contain double underscore.",
    },
  },

  survey: {
    survey: "Survey",
    betaTesting: "Beta testing",
    betaTestingResults: "Beta testing results",
    landingTitle: "Hi there, we value your feedback!",
    landingSubtitle:
      "Help us improve your gaming experience by taking our short survey.",
    landingButton: "Give feedback",
    greeting: "Hi {username},",
    description:
      "your opinion matters to us! This survey shouldn’t take longer than 15 minutes of your precious time. This feedback is very helpful for our team in testing the existing and developing the new features of this metaverse. Thank you so much for taking the time to complete this survey!",
    ratings: "1 = Poor; 2 = Fair; 3 = Good; 4 = Very good; 5 = Excellent",
    comment: "Comment",
    commentInput:
      "Please let us know if you have any further comments/suggestions?",
    commentInputPlaceholder:
      "Explain in some detail what could be improved. Suggestion on login design, avatar selection process, ingame challanges or any other comment will be very helpful in creating a better metaverse experience for you... ",
    completedMessage: "Thank you for your time and have a nice day!",
    alreadyCompletedMessage: "You have already completed this survey. Thanks!",
    download: "Download this survey",
    exportSelected: "Export selected",

    stats: {
      total_surveys: "Total participants",
      average_impressions: "Average impression",
      most_used_device: "Most used device",
    },
  },

  users: {
    profile: "Profile",
    follow: "Follow",
    followers: "Followers",
    unfollow: "Unfollow",
    following: "Following",
    message: "Message",
    noFollowers: "User doesn't have any followers.",
    noFollowings: "User doesn't follow anyone.",
    followedUsers: "Followed users",
    changePassword: "Change password",
    currentPassword: "Current password",
    newPassword: "New password",
    confirmNewPassword: "Repeat new password",
    accountVerified: "This account is verified.",
    privateProfileAlert:
      "This profile is private. You must follow this user to view full profile.",
    blockedProfileAlert: "Sorry, you can't view this profile.",
    blockConfirm: "Are you sure you want to block this user?",
    ban: "Ban user",
    unban: "Unban user",
    delete: "Delete user",
    noSuggested: "No suggested users.",
    noResults: "No users found.",
    images: "Images",
    seeAll: "See all",
    noPosts: "This user has not created a post yet.",
    followRequestAccepted: "You accepted follow request from {username}.",
    followRequestRejected: "You denied follow request from {username}.",
  },

  wallet: {
    cfoot: "C-Foot",
    activity: "Activity",
    receive: "Receive",
    send: "Send",
    balance: "Balance",
    tokens: "Tokens",
    nfts: "NFTs",
    latestTransactions: "Latest transactions",
    transferTo: "Transfer to {s}",
    transferFrom: "Transfer from {s}",
    howMuchToSend: "How much do you want to send?",
    whereToSend: "Where to?",
    pasteAddress: "Paste address here",
    enterAmount: "Enter amount here",
    yourDepositAddress: "Your deposit address",
    addressCopied: "Address copied!",
    connect: {
      title: "Connect to your MetaMask wallet!",
      subtitle: "Register to the network via your MetaMask wallet.",
      button: "Connect MetaMask",
    },
  },
};
