import Vue from "vue";
import Vuex from "vuex";

import admin from "./modules/admin";
import app from "./modules/app";
import auth from "./modules/auth";
import chat from "./modules/chat";
import comments from "./modules/comments";
import contacts from "./modules/contacts";
import clubs from "./modules/clubs";
import hashtags from "./modules/hashtags";
import feed from "./modules/feed";
import follows from "./modules/follows";
import groups from "./modules/groups";
import notifications from "./modules/notifications";
import polls from "./modules/polls";
import posts from "./modules/posts";
import reports from "./modules/reports";
import search from "./modules/search";
import surveys from "./modules/surveys";
import users from "./modules/users";
import wallet from "./modules/wallet";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    admin,
    app,
    auth,
    chat,
    comments,
    contacts,
    clubs,
    hashtags,
    feed,
    follows,
    groups,
    notifications,
    polls,
    posts,
    reports,
    search,
    surveys,
    users,
    wallet,
  },
});
