import auth from "../middleware/auth";
import role from "../middleware/role";

export default {
  path: "/admin",
  meta: {
    middleware: [auth, role("ROLE_ADMIN")],
  },
  component: () =>
    import(
      /* webpackChunkName: "AppLayout" */
      "@/components/layouts/app/AppLayout"
    ),
  children: [
    {
      path: "dashboard",
      alias: "",
      name: "admin",
      component: () =>
        import(
          /* webpackChunkName: "DashboardPage" */
          "@/pages/admin/DashboardPage"
        ),
    },
    {
      path: "users",
      name: "admin.users",
      component: () =>
        import(
          /* webpackChunkName: "UsersPage" */
          "@/pages/admin/UsersPage"
        ),
    },
    {
      path: "reports",
      name: "admin.reports",
      component: () =>
        import(
          /* webpackChunkName: "ReportsPage" */
          "@/pages/admin/ReportsPage"
        ),
    },
    {
      path: "hashtags",
      name: "admin.hashtags",
      component: () =>
        import(
          /* webpackChunkName: "HashtagsPage" */
          "@/pages/admin/HashtagsPage"
        ),
    },
    {
      path: "survey",
      name: "admin.survey",
      component: () =>
        import(
          /* webpackChunkName: "SurveyPage" */
          "@/pages/admin/SurveyPage"
        ),
    },
  ],
};
