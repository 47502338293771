<template>
  <v-dialog :value="true" persistent width="500">
    <v-card>
      <!-- Title -->
      <v-card-title class="pa-1">
        <v-spacer />
        <close-button @click="cancel" />
      </v-card-title>

      <!-- Text -->
      <v-card-text class="text-center text-formatted font-weight-medium">
        {{ params.text || $t("common.confirmText") }}
      </v-card-text>

      <!-- Actions -->
      <v-card-actions class="justify-center pb-7">
        <!-- Cancel -->
        <v-btn color="secondary" rounded @click="cancel">
          {{ params.cancelButtonText || $t("common.cancel") }}
        </v-btn>

        <!-- Confirm -->
        <v-btn color="primary" rounded :loading="loading" @click="confirm">
          {{ params.confirmButtonText || $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    params: {
      type: Object,
      required: true,
    },
  },

  computed: {
    loading() {
      return this.params.dispatch
        ? !!this.$loading(this.params.dispatch.action)
        : false;
    },
  },

  destroyed() {
    this.removeOverlay();
  },

  methods: {
    confirm() {
      if (!this.params.dispatch) {
        return this.$emit("resolve", true);
      }

      this.$store
        .dispatch(this.params.dispatch.action, this.params.dispatch.params)
        .then((res) => {
          this.$emit("resolve", res);
        })
        .catch((data) => {
          this.$emit("reject", data);
        });
    },

    cancel() {
      this.$emit("resolve", false);
    },

    removeOverlay() {
      document
        .querySelectorAll("#app > .v-overlay:not(.v-overlay--active)")
        .forEach((e) => e.remove());
    },
  },
};
</script>

<style></style>
