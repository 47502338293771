import Vue from "vue";
import i18n from "./i18n";
import Vuetify from "vuetify/lib/framework";
import en from "vuetify/es5/locale/en";
import de from "vuetify/es5/locale/de";
import minifyTheme from "minify-css-string";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true,
      minifyTheme,
      // themeCache: {
      //   get: (key) => localStorage.getItem(key),
      //   set: (key, value) => localStorage.setItem(key, value),
      // },
    },
    themes: {
      dark: {
        primary: "#38B54A",
        secondary: "#0B2540",
        anchor: "#F4F4F4",
        success: "#008A00",
        info: "#0F5EA2",
        warning: "#EB8F00",
      },
    },
  },
  lang: {
    locales: { en, de },
    current: i18n.locale,
    defaultLocale: "en",
  },
});
