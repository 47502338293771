import i18n from "@/plugins/i18n";

export default {
  attachments: {
    imageMimes: ".jpg,.jpeg,.png,.svg",
    videoMimes: ".mp4,.avi,.mov,.ogg,.qt",
    audioMimes:
      ".mpga,.mp3,.wav,.mov,audio/mpeg,audio/mpeg4-generic,audio/mp3,audio/mpga",
    documentMimes: ".pdf,.txt,.pdf",
    maxImageSize: 10000,
    maxVideoSize: 50000,
    maxAudioSize: 10000,
    maxDocumentSize: 10000,
  },
  contacts: {
    maxNameLength: 64,
  },
  dates: {
    dateFormat: "DD.MM.YYYY",
    dateTimeFormat: "DD.MM.YYYY HH:mm",
    timeFormat: "HH:mm",
  },
  feed: {
    postsPerPage: 10,
    suggestionsNumber: 4,
  },
  groups: {
    maxDescriptionLength: 300,
    maxNameLength: 32,
    maxHashtags: 10,
    minDescriptionLength: 32,
    minNameLength: 4,
    minHashtags: 3,
    joinedGroupsPerPage: 10,
  },
  likes: {
    likesPerPage: 8,
  },
  media: {
    perPage: 20,
  },
  metaInfo: {
    titleTemplate: "%s | Payfoot",
    htmlAttrs: { lang: i18n.locale },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
  notifications: {
    perPage: 10,
    modal: {
      perPage: 5,
    },
  },
  polls: {
    minDuration: 10,
    perPage: 10,
  },
  posts: {
    commentsPerPage: 5,
    commentRepliesPerPage: 3,
    maxCommentLength: 500,
    maxContentLength: 1000,
  },
  profile: {
    maxBioLength: 180,
    maxNameLength: 50,
    minNameLength: 2,
    followsPerPage: 6,
  },
  reports: {
    categories: ["post", "comment", "user", "group"],
    types: [
      "spam",
      "scam",
      "offensive_language",
      "violence",
      "adult_content",
      "fake",
    ],
  },
  stories: {
    length: 8,
  },
  username: {
    maxLength: 15,
    minLength: 4,
    blacklist: ["admin", "moderator", "payfoot", "community", "hitler"],
  },
  vuetify: {
    vDataTable: {
      footerProps: {
        itemsPerPageOptions: [5, 10, 15, 50],
      },
    },
  },
};
