<template>
  <!-- Img -->
  <v-hover v-slot="{ hover }">
    <v-img
      class="image-input"
      :class="{ 'cursor-pointer': !readOnly }"
      :src="imageUrl"
      alt="Image"
      v-bind="$attrs"
      @click="onImageClick"
    >
      <!-- Loader -->
      <spinner v-if="loading" center />

      <!-- Tab to change -->
      <div
        v-if="!readOnly && hover"
        class="d-flex align-center justify-center w-100 h-100"
        style="background-color: rgba(0, 0, 0, 0.3)"
      >
        <div class="font-weight-medium" style="margin-top: -20px">
          {{ $t("common.tapToChange") }}
        </div>
      </div>

      <!-- Cropper modal -->
      <CropperModal
        :image="cropperImage"
        @cancel="onCropperCancel"
        @save="onCropperSave"
        rectangle
      />

      <!-- Hidden file input -->
      <input
        type="file"
        :accept="$config.attachments.imageMimes"
        :ref="fileInputRef"
        class="d-none"
        @change="onFileInputChange"
      />
    </v-img>
  </v-hover>
</template>

<script>
import CropperModal from "@/components/image-cropper/CropperModal.vue";

export default {
  name: "ImageInput",

  inheritAttrs: false,

  components: { CropperModal },

  props: {
    value: {
      type: [Object, File],
    },

    placeholderSize: {
      type: String,
      default: "100x100",
    },

    readOnly: Boolean,
    loading: Boolean,
    rectangle: Boolean,
  },

  data() {
    return {
      fileInputRef: null,
      cropperImage: null,
      image: null,
    };
  },

  computed: {
    imageUrl() {
      if (this.image) {
        return URL.createObjectURL(this.image);
      }

      if (this.value?.image_url) {
        return this.value.image_url;
      }

      return this.$utils.placeholderImage(this.placeholderSize);
    },
  },

  watch: {
    image(val) {
      this.$emit("input", val);
    },
  },

  created() {
    this.fileInputRef = "avatar_input_" + new Date().getTime();
  },

  methods: {
    onImageClick() {
      if (this.readOnly) {
        return;
      }

      // Open file input
      this.$refs[this.fileInputRef].click();
    },

    onFileInputChange(e) {
      if (e.target.files.length) {
        this.cropperImage = e.target.files;
      } else {
        this.image = null;
      }

      this.$nextTick(() => {
        e.target.value = "";
      });
    },

    onCropperSave(e) {
      this.image = e;
    },

    onCropperCancel() {
      this.$refs[this.fileInputRef].value = null;
    },
  },
};
</script>

<style lang="scss"></style>
