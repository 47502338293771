<template>
  <v-avatar
    :size="size"
    class="avatar avatar-input"
    :class="{ 'cursor-pointer': !readOnly }"
    @click="onAvatarClick"
    v-bind="$attrs"
  >
    <!-- Img -->
    <v-hover v-slot="{ hover }">
      <v-img :src="imageUrl" alt="Avatar image">
        <!-- Loader -->
        <spinner v-if="loading" center />

        <!-- Tap to change -->
        <small
          v-if="!readOnly && hover"
          class="d-flex align-center justify-center w-100 h-100"
          style="background-color: rgba(0, 0, 0, 0.3)"
        >
          <v-icon>mdi-camera</v-icon>
        </small>
      </v-img>
    </v-hover>

    <!-- Cropper modal -->
    <CropperModal
      :image="cropperImage"
      @cancel="onCropperCancel"
      @save="onCropperSave"
      circle
    />

    <!-- Hidden file input -->
    <input
      type="file"
      :accept="$config.attachments.imageMimes"
      ref="input"
      class="d-none"
      @change="onFileInputChange"
    />
  </v-avatar>
</template>

<script>
import CropperModal from "@/components/image-cropper/CropperModal.vue";

export default {
  name: "AvatarInput",

  components: { CropperModal },

  props: {
    value: {
      type: [Object, File, String],
    },
    size: {
      type: String,
      default: "100",
    },
    user: Object,
    readOnly: Boolean,
    loading: Boolean,
  },

  data() {
    return {
      cropperImage: null,
      image: null,
    };
  },

  computed: {
    imageUrl() {
      if (typeof this.value === "string") {
        return this.value;
      }

      if (this.image) {
        return URL.createObjectURL(this.image);
      }

      if (this.value?.image_url) {
        return this.value.image_url;
      }

      return this.$utils.placeholderImage(this.size);
    },
  },

  watch: {
    image(val) {
      this.$emit("input", val);
    },
  },

  methods: {
    onAvatarClick() {
      if (this.readOnly) {
        return;
      }

      // Open file input
      this.$refs.input.click();
    },

    onFileInputChange(e) {
      if (e.target.files.length) {
        this.cropperImage = e.target.files;
      } else {
        this.image = null;
      }

      this.$nextTick(() => {
        e.target.value = "";
      });
    },

    onCropperSave(e) {
      this.image = e;
    },

    onCropperCancel() {
      this.$refs[this.fileInputRef].value = null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
