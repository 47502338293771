import { default as $dayjs } from "@/plugins/dayjs";
import router from "@/router";
import config from "@/config/config";

export const $env = {
  get: () => env("ENV"),
  prod: env("ENV") === "prod",
  dev: env("ENV") === "dev",
  maint: env("ENV") === "maint",
};

export function canLoadMore(meta) {
  return (
    meta &&
    (meta.next_cursor || (meta.last_page && meta.last_page > meta.current_page))
  );
}

export function dayjs(...args) {
  return $dayjs(...args);
}

export function env(variableName) {
  return process.env[`VUE_APP_${variableName}`];
}

export function handleError(error) {
  switch (error.status) {
    case 401:
      break;

    case 404:
      router.replace({
        name: "notFound",
        query: {
          from: router.currentRoute.path,
        },
      });
      break;

    default:
      router.replace({
        name: "notFound",
        query: {
          from: router.currentRoute.path,
        },
      });
      break;
  }
}

export function formatDate(date) {
  return date ? $dayjs(date).format(config.dates.dateFormat) : null;
}

export function formatDatetime(date) {
  return date ? $dayjs(date).format(config.dates.dateTimeFormat) : null;
}

export function formatTime(date) {
  return date ? $dayjs(date).format(config.dates.timeFormat) : null;
}

export function groupRoute(group) {
  return { name: "app.group", params: { id: group.id } };
}

export function log(...args) {
  console.log(...args);
}

export function placeholderImage(
  size = "100x100",
  text = "Payfoot",
  color = "555555"
) {
  return `https://placehold.jp/${color}/${size}.png?text=${text}`;
}

export function roleCheck(roles, user) {
  if (!user || !user.roles) {
    return false;
  }

  if (typeof roles === "string") {
    roles = [roles];
  }

  for (const role of roles) {
    if (user.roles.includes(role)) {
      return true;
    }
  }

  return false;
}

export function storeErrorHandler(err) {
  const data = err?.response?.data || err;
  data.status = err?.response?.status;

  console.log(data);
  return Promise.reject(data);
}

export function updateObject(target, src, vue = null) {
  for (const key in target) {
    if (Object.hasOwnProperty.call(target, key)) {
      if (vue) {
        vue.$set(target, key, src[key]);
      } else {
        target[key] = src[key];
      }
    }
  }
}

export function userImage(user, phSize = "50x50") {
  if (user && user.image) {
    const size = Number(phSize.split("x")[0]);
    const checkThumbnail = size && size <= 100;

    if (checkThumbnail) {
      return user.image?.thumbnail_url || user.image?.image_url;
    }

    return user.image?.image_url;
  }

  const phText = user?.username?.substring(0, 2).toUpperCase() || "Payfoot";

  return placeholderImage(phSize, phText);
}

export function userRoute(user) {
  return { name: "app.user", params: { id: user.username } };
}

export const wait = (timeout) => {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};
