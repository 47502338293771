import axios from "@/plugins/axios";
import { storeErrorHandler } from "@/utils/utils";
import config from "@/config/config";

// State
const state = {
  loading: {
    fetchVotes: null,
    vote: null,
  },
};

// Getters
const getters = {
  loading: (state) => state.loading,
};

// Mutations
const mutations = {
  SET_LOADING(state, { key, value }) {
    state.loading[key] = value;
  },
};

// Actions
const actions = {
  async fetchVotes({ commit }, { id, params = {} }) {
    try {
      commit("SET_LOADING", { key: "fetchVotes", value: id });

      params.per_page = config.polls.perPage;

      const { data } = await axios.get(`polls/${id}/votes`, { params });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchVotes", value: null });
    }
  },

  async vote({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "vote", value: id });

      const { data } = await axios.put(`polls/vote/${id}`);

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "vote", value: null });
    }
  },
};

// Export
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
