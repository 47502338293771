<template>
  <div class="file-input">
    <!-- Button -->
    <v-btn
      class="btn"
      v-bind="$attrs"
      text
      small
      :icon="iconOnly"
      @click="$refs.fileInput.$refs.input.click()"
    >
      <v-icon :left="!iconOnly" :size="iconSize || 20">
        {{ icon || options.icon }}
      </v-icon>

      <template v-if="!iconOnly && options.text">
        {{ btnText || options.text }}
      </template>
    </v-btn>

    <!-- File input -->
    <v-file-input
      v-model="model"
      ref="fileInput"
      class="d-none"
      :multiple="multiple"
      :accept="accept || options.accept"
    />
  </div>
</template>

<script>
export default {
  name: "FileInput",

  props: {
    value: [Array, File, Object],
    type: String,
    accept: String,
    multiple: Boolean,
    append: Boolean,
    forceArray: Boolean,

    max: [String, Number],

    btnText: String,
    icon: String,
    iconSize: [String, Number],
    iconOnly: Boolean,
  },

  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        if (this.append) {
          if (Array.isArray(val)) {
            this.$emit("input", [...this.value, ...val]);
          } else {
            this.$emit("input", [...this.value, val]);
          }
        } else {
          if (this.forceArray && !Array.isArray(val)) {
            this.$emit("input", [val]);
          } else {
            this.$emit("input", val);
          }
        }
      },
    },

    options() {
      switch (this.type) {
        case "image":
          return {
            accept: this.$config.attachments.imageMimes,
            icon: "mdi-image",
            text: this.$t("common.image"),
          };

        case "video":
          return {
            accept: this.$config.attachments.videoMimes,
            icon: "mdi-video",
            text: this.$t("common.video"),
          };

        case "image,video":
          return {
            accept: `${this.$config.attachments.imageMimes},${this.$config.attachments.videoMimes}`,
            icon: "mdi-image",
            text: this.btnText,
          };

        case "document":
          return {
            accept: this.$config.attachments.documentMimes,
            icon: "mdi-file-document",
            text: this.btnText,
          };

        default:
          return {
            accept: "*",
            icon: "mdi-file-upload",
            text: this.btnText,
          };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:deep {
  .v-btn__content {
    font-weight: normal !important;
    font-size: 14px;
  }
}
</style>
