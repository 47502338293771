/**
 * plugins/app.js
 */
import Vue from "vue";

Vue.component("column-layout", () =>
  import("@/components/layouts/ColumnLayout.vue")
);

// Import control components
let requireComponent = require.context("@/components/core", true, /\.vue$/);

for (const file of requireComponent.keys()) {
  const componentConfig = requireComponent(file);

  Vue.component(
    componentConfig.default.name,
    componentConfig.default || componentConfig
  );
}
