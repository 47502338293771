import axios from "@/plugins/axios";
import Echo from "laravel-echo";
import Larasocket from "larasocket-js";
import eventBus from "@/utils/eventBus";
import store from "@/store";
import { env } from "@/utils/utils";

window.Echo = new Echo({
  broadcaster: Larasocket,
  token: env("LARASOCKET_TOKEN"),
  authorizer: (channel) => ({
    authorize: (socketId, callback) => {
      if (!localStorage.getItem("token")) {
        return callback(true, "No access token.");
      }

      axios
        .post("broadcasting/auth", {
          socket_id: socketId,
          channel_name: channel.name,
        })
        .then((response) => {
          callback(false, response);
        })
        .catch((error) => {
          callback(true, error);
        });
    },
  }),
});

// Listen channels
eventBus.$on("auth:authenticated", (user) => {
  window.Echo.private(`users.${user.id}`)
    .listen("NewMessageEvent", (e) => {
      eventBus.$emit("chat:received", e);
      store.dispatch("chat/handleIncomingMessage", e.message);
    })
    .listen("MessageUnsentEvent", ({ message }) => {
      eventBus.$emit("chat:unsent", message);
    })
    .listen("NewNotificationEvent", ({ notification }) => {
      store.commit("notifications/ADD", notification);
    });
});

// Leave channels
eventBus.$on("auth:logout", (user) => {
  window.Echo.leave(`users.${user.id}`);
});
