import { serialize } from "object-to-formdata";
import config from "@/config/config";
import axios from "@/plugins/axios";
import eventBus from "@/utils/eventBus";
import { storeErrorHandler } from "@/utils/utils";

const onPostCreated = (post) => {
  post.fresh = true;

  // Fix "in a few seconds"
  post.created_at = post.updated_at = new Date(Date.now() - 1000).toUTCString();

  eventBus.$emit("post:created", post);
};

// State
const state = {
  loading: {
    fetch: false,
    fetchFeed: false,
    fetchByUser: false,
    fetchByGroup: false,
    create: false,
    update: null,
    delete: null,
    share: false,
    fetchLikes: null,
    toggleLike: null,
    deleteAttachment: null,
    fetchSingle: null,
  },
};

// Getters
const getters = {
  loading: (state) => state.loading,
};

// Mutations
const mutations = {
  SET_LOADING: (state, { key, value }) => (state.loading[key] = value),
};

// Actions
const actions = {
  async fetch({ commit }, params = {}) {
    try {
      commit("SET_LOADING", { key: "fetch", value: true });

      params.per_page = config.feed.postsPerPage;

      const { data } = await axios.get("posts", { params });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetch", value: false });
    }
  },

  async fetchFeed({ commit }, params = {}) {
    try {
      commit("SET_LOADING", { key: "fetchFeed", value: true });

      params.per_page = config.feed.postsPerPage;

      const { data } = await axios.get("feed-following", { params });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchFeed", value: false });
    }
  },

  async fetchByUser({ commit }, { id, params = {} }) {
    try {
      commit("SET_LOADING", { key: "fetchByUser", value: true });

      params.per_page = config.feed.postsPerPage;

      const { data } = await axios.get("users/posts/" + id, { params });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchByUser", value: false });
    }
  },

  async fetchByGroup({ commit }, { id, params = {} }) {
    try {
      commit("SET_LOADING", { key: "fetchByGroup", value: true });

      params.per_page = config.feed.postsPerPage;

      const { data } = await axios.get("groups/post/" + id, { params });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchByGroup", value: false });
    }
  },

  async create({ commit }, payload) {
    try {
      commit("SET_LOADING", { key: "create", value: true });

      const { data } = await axios.post(
        "posts",
        serialize(payload, { nullsAsUndefineds: true })
      );

      const post = data.data;
      onPostCreated(post);

      return post;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "create", value: false });
    }
  },

  async createGroupPost({ commit }, { id, payload }) {
    try {
      commit("SET_LOADING", { key: "create", value: true });

      const { data } = await axios.post(
        "groups/post/" + id,
        serialize(payload)
      );

      const post = data.data;
      onPostCreated(post);

      return post;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "create", value: false });
    }
  },

  async update({ commit }, { id, payload }) {
    try {
      commit("SET_LOADING", { key: "update", value: id });

      payload._method = "PUT";
      const { data } = await axios.post("posts/" + id, serialize(payload));

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "update", value: null });
    }
  },

  async delete({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "delete", value: id });

      const { data } = await axios.delete("posts/" + id);

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "delete", value: null });
    }
  },

  async share({ commit }, payload) {
    try {
      commit("SET_LOADING", { key: "share", value: true });

      const { data } = await axios.post("share-post", serialize(payload));

      const post = data.data;
      onPostCreated(post);

      return post;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "share", value: false });
    }
  },

  async fetchLikes({ commit }, { id, params = {} }) {
    try {
      commit("SET_LOADING", { key: "fetchLikes", value: id });

      params.per_page = config.likes.likesPerPage;

      let { data } = await axios.get("post-likes/" + id, { params });
      data = {
        data: data.post_likes.data.map((i) => ({
          ...i.user,
          like_type: i.like_type,
        })),
        meta: data.post_likes.meta,
        like_types: data.total_like_types,
      };

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchLikes", value: null });
    }
  },

  async toggleLike({ commit }, { id, type }) {
    try {
      commit("SET_LOADING", { key: "toggleLike", value: id });

      const { data } = await axios.post("post-likes", {
        post_id: id,
        like_type: type,
      });

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "toggleLike", value: null });
    }
  },

  async deleteAttachment({ commit }, { id, attachmentId }) {
    try {
      commit("SET_LOADING", { key: "deleteAttachment", value: attachmentId });

      const { data } = await axios.delete(
        `post/${id}/attachment/${attachmentId}`
      );

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "deleteAttachment", value: null });
    }
  },

  async fetchSingle({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "fetchSingle", value: id });

      const { data } = await axios.get("posts/" + id);

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchSingle", value: null });
    }
  },
};

// Export
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
