<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    class="close-button cursor-pointer text--white"
    color="transparent"
    fab
    small
  >
    <v-icon>mdi-close</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "CloseButton",
};
</script>

<style lang="scss" scoped></style>
