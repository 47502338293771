import axios from "@/plugins/axios";
import { storeErrorHandler } from "@/utils/utils";

// State
const state = {
  loading: {
    fetch: false,
    fetchByUser: null,
    update: null,
  },
};

// Getters
const getters = {
  loading: (state) => state.loading,
};

// Mutations
const mutations = {
  SET_LOADING: (state, { key, value }) => (state.loading[key] = value),
};

// Actions
const actions = {
  async fetch({ commit }, params) {
    try {
      commit("SET_LOADING", { key: "fetch", value: true });

      const { data } = await axios.get("contacts", { params });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetch", value: false });
    }
  },

  async fetchByUser({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "fetchByUser", value: id });

      const { data } = await axios.get("contacts/" + id);

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchByUser", value: null });
    }
  },

  async update({ commit }, { id, payload }) {
    try {
      commit("SET_LOADING", { key: "update", value: id });

      const { data } = await axios.put("contacts/" + id, payload);

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "update", value: null });
    }
  },
};

// Export
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
