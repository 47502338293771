<template>
  <v-text-field
    v-model="model"
    v-bind="$attrs"
    v-on="$listeners"
    :type="showPassword ? 'text' : 'password'"
  >
    <template v-slot:append>
      <v-icon @click="showPassword = !showPassword" tabindex="-1">
        {{ showPassword ? "mdi-eye-off-outline" : "mdi-eye-outline" }}
      </v-icon>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "PasswordInput",

  inheritAttrs: false,

  props: {
    value: {
      type: String,
    },
  },

  data: () => ({
    showPassword: false,
  }),

  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style></style>
