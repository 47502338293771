import axios from "@/plugins/axios";
import { storeErrorHandler } from "@/utils/utils";

// State
const state = {
  all: [],
  loading: {
    fetchAll: false,
    create: false,
    update: false,
    delete: null,
    addclub: null,
  },
};

// Getters
const getters = {
  all: (state) => state.all,
  loading: (state) => state.loading,
};

// Mutations
const mutations = {
  SET_ALL: (state, value) => (state.all = value),
  SET_LOADING: (state, { key, value }) => (state.loading[key] = value),
};

// Actions
const actions = {
  async fetchAll({ commit, state }, force = false) {
    try {
      if (!force && state.all.length > 0) {
        return;
      }

      commit("SET_LOADING", { key: "fetchAll", value: true });

      const res = await axios.get("football-clubs", {
        params: {
          "sort[name]": "asc",
        },
      });
      commit("SET_ALL", res.data.data);

      return res;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchAll", value: false });
    }
  },

  //   async add({ commit }, club) {
  //     try {
  //       commit("SET_LOADING", { key: "addclub", value: true });

  //       const res = await axios.post("club", {
  //         name: club,
  //       });

  //       return res.data;
  //     } catch (err) {
  //       return storeErrorHandler(err);
  //     } finally {
  //       commit("SET_LOADING", { key: "addclub", value: false });
  //     }
  //   },
  //   async delete({ commit }, id) {
  //     try {
  //       commit("SET_LOADING", { key: "deleteclub", value: true });
  //       const url = `club/${id}`;
  //       const res = await axios.delete(url);

  //       return res.data;
  //     } catch (err) {
  //       return storeErrorHandler(err);
  //     } finally {
  //       commit("SET_LOADING", { key: "deleteclub", value: false });
  //     }
  //   },
  //   async statistics({ commit }) {
  //     try {
  //       commit("SET_LOADING", { key: "statisticsclub", value: true });
  //       const url = `statistics/clubs`;
  //       const res = await axios.get(url);

  //       return res.data;
  //     } catch (err) {
  //       return storeErrorHandler(err);
  //     } finally {
  //       commit("SET_LOADING", { key: "statisticsclub", value: false });
  //     }
  //   },
};

// Export
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
