import authMiddleware from "../middleware/auth";

export default {
  path: "/",
  component: () =>
    import(
      /* webpackChunkName: "DefaultLayout" */
      "@/components/layouts/DefaultLayout"
    ),
  children: [
    {
      path: "",
      redirect: { name: "app" },
    },
    {
      path: "/survey",
      name: "survey",
      meta: {
        middleware: [authMiddleware],
        background: false,
      },
      component: () =>
        import(
          /* webpackChunkName: "SurveyPage" */
          "@/pages/SurveyPage"
        ),
    },
    {
      path: "/privacy-policy",
      name: "privacyPolicy",
      component: () =>
        import(
          /* webpackChunkName: "PrivacyPolicyPage" */
          "@/pages/PrivacyPolicyPage"
        ),
    },
    {
      path: "/maintenance",
      name: "maint",
      component: () =>
        import(
          /* webpackChunkName: "MaintenancePage" */
          "@/pages/MaintenancePage"
        ),
    },
    {
      path: "/not-found",
      alias: "*",
      name: "notFound",
      component: () =>
        import(
          /* webpackChunkName: "NotFoundPage" */
          "@/pages/NotFoundPage"
        ),
    },
  ],
};
