import axios from "@/plugins/axios";
import config from "@/config/config";
import { storeErrorHandler } from "@/utils/utils";
import Vue from "vue";
import i18n from "@/plugins/i18n";
import { playAudio } from "@/utils/helpers";

// State
const state = {
  all: {
    data: [],
    meta: null,
  },

  unread: {
    data: [],
    meta: null,
  },

  hasUnread: false,

  loading: {
    fetchAll: false,
    fetchUnread: false,
    markAsRead: null,
    markAllAsRead: false,
    delete: null,
  },
};

// Getters
const getters = {
  all: (state) => state.all,
  unread: (state) => state.unread,

  hasUnread: (state) => state.hasUnread,

  loading: (state) => state.loading,
};

// Mutations
const mutations = {
  SET_ALL: (state, value) => (state.all = value),
  SET_UNREAD: (state, value) => (state.unread = value),

  ADD: (state, value) => {
    if (state.all.meta) {
      state.all.data.unshift(value);
      ++state.all.meta.total;
    }

    state.unread.data.unshift(value);
    ++state.unread.meta.total;

    state.hasUnread = true;

    playAudio("notification.mp3");

    // Show toast notification
    Vue.$toast.info(i18n.t("notifications.newNotificationAlert"));
  },

  MARK_ALL_AS_READ: (state) => {
    if (state.all.meta) {
      state.all.data.forEach((i) => (i.is_read = true));
    }

    state.unread.data = [];
    state.unread.meta.total = 0;

    state.hasUnread = false;
  },

  DELETE: (state, notification) => {
    if (state.all.meta) {
      const i = state.all.data.findIndex((i) => i.id === notification.id);

      if (i !== -1) {
        delete state.all.data.splice(i, 1);
        --state.all.meta.total;
      }
    }

    if (state.unread.meta) {
      const i = state.unread.data.findIndex((i) => i.id === notification.id);

      if (i !== -1) {
        state.unread.data.splice(i, 1);

        if (!notification.is_read) {
          --state.unread.meta.total;
          state.hasUnread = !!state.unread.meta.total;
        }
      }
    }
  },

  CLEAR: (state) => {
    state.all = {
      data: [],
      meta: null,
    };

    state.unread = {
      data: [],
      meta: null,
    };

    state.hasUnread = false;
  },

  SET_LOADING: (state, { key, value }) => (state.loading[key] = value),
};

// Actions
const actions = {
  async fetchAll({ commit, state }, loadMore = false) {
    if (state.all.data.length && !loadMore) {
      return state.all;
    }

    try {
      commit("SET_LOADING", { key: "fetchAll", value: true });

      const params = {
        per_page: config.notifications.perPage,
      };

      if (loadMore) {
        params.page = state.all.meta.current_page + 1;
      }

      const { data } = await axios.get("notifications", { params });

      if (loadMore) {
        data.data = state.all.data.concat(data.data);
      }

      commit("SET_ALL", data);
      state.hasUnread = data.has_unread;

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchAll", value: false });
    }
  },

  async fetchUnread({ commit, state }, loadMore = false) {
    if (state.unread.data.length && !loadMore) {
      return state.unread;
    }

    try {
      commit("SET_LOADING", { key: "fetchUnread", value: true });

      const params = {
        per_page: config.notifications.perPage,
        "exact_search[is_read]": 0,
      };

      if (loadMore) {
        params.page = state.unread.meta.current_page + 1;
      }

      const { data } = await axios.get("notifications", { params });

      if (loadMore) {
        data.data = state.unread.data.concat(data.data);
      }

      commit("SET_UNREAD", data);
      state.hasUnread = data.has_unread;

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchUnread", value: false });
    }
  },

  async markAsRead({ commit, state, dispatch }, id) {
    try {
      commit("SET_LOADING", { key: "markAsRead", value: id });

      const { data } = await axios.put(`notifications/mark-as-read/${id}`);
      state.hasUnread = data.data.has_unread;

      const index = state.unread.data.findIndex((i) => i.id === id);
      if (index !== -1) {
        state.unread.data.splice(index, 1);
        --state.unread.meta.total;
      }

      if (state.hasUnread && !state.unread.data.length) {
        dispatch("fetchUnread");
      }

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "markAsRead", value: null });
    }
  },

  async markAllAsRead({ commit }) {
    try {
      commit("SET_LOADING", { key: "markAllAsRead", value: true });

      const { data } = await axios.put(`notifications/mark-as-read`);

      commit("MARK_ALL_AS_READ");

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "markAllAsRead", value: null });
    }
  },

  async delete({ commit }, notification) {
    try {
      commit("SET_LOADING", { key: "delete", value: notification.id });

      const { data } = await axios.delete(`notifications/${notification.id}`);

      commit("DELETE", notification);

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "delete", value: null });
    }
  },
};

// Export
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
