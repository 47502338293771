import store from "@/store";

export default function ({ next }) {
  const user = store.getters["auth/user"];

  if (user) {
    return next({ name: "app" });
  }

  return next();
}
