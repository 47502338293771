import store from "@/store";

export function downloadFile(file, name = "file") {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function playAudio(file) {
  if (store.getters["auth/user"].account_settings.notification_sound_off) {
    return;
  }

  new Audio(require("@/assets/audio/" + file)).play().catch(() => {});
}
