import { createPromiseDialog } from "vue-promise-dialogs";
import { $env } from "@/utils/utils";
import eventBus from "@/utils/eventBus";
import config from "@/config/config";
import rules from "@/utils/rules";
import * as utils from "@/utils/utils";
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";

export default {
  computed: {
    $config() {
      return config;
    },

    $env() {
      return $env;
    },

    $eventBus() {
      return eventBus;
    },

    $rules() {
      return rules;
    },

    $user() {
      return this.$store.getters["auth/user"];
    },

    $utils() {
      return utils;
    },

    $xs() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },

  methods: {
    $confirm(params = {}) {
      return createPromiseDialog(ConfirmDialog)(params);
    },

    $loading(path) {
      const [module, field] = path.split("/");
      return this.$store.getters[`${module}/loading`][field];
    },
  },
};
