import axios from "@/plugins/axios";
import { dayjs } from "@/utils/utils";

// State
const state = {
  lastPing: null,
};

// Getters
const getters = {};

// Mutations
const mutations = {};

// Actions
const actions = {
  ping({ state }) {
    if (dayjs().diff(state.lastPing, "minutes") < 3) {
      return;
    }

    state.lastPing = dayjs();
    return axios.post("ping");
  },
};

// Export
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
