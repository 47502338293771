import axios from "@/plugins/axios";
import { storeErrorHandler } from "@/utils/utils";

// State
const state = {
  loading: {
    statsByType: false,
    chartByType: false,
    banUser: null,
    setAdminRole: null,
    removeAdminRole: null,
  },
};

// Getters
const getters = {
  loading: (state) => state.loading,
};

// Mutations
const mutations = {
  SET_LOADING(state, { key, value }) {
    state.loading[key] = value;
  },
};

// Actions
const actions = {
  async statsByType({ commit }, type) {
    try {
      commit("SET_LOADING", { key: "statsByType", value: type });

      const { data } = await axios.get("statistics/" + type);

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "statsByType", value: false });
    }
  },

  async chartByType({ commit }, { type, interval }) {
    try {
      commit("SET_LOADING", { key: "chartByType", value: type });

      const { data } = await axios.get(`chart-by-type/${type}/${interval}`);

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "chartByType", value: false });
    }
  },

  async banUser({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "banUser", value: id });

      const res = await axios.put("ban-user/" + id);

      return res.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "banUser", value: null });
    }
  },

  async setAdminRole({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "setAdminRole", value: id });

      const { data } = await axios.put(`users/add-admin/${id}`);

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "setAdminRole", value: null });
    }
  },

  async removeAdminRole({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "removeAdminRole", value: id });

      const { data } = await axios.put(`users/remove-admin/${id}`);

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "removeAdminRole", value: null });
    }
  },
};

// Export
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
