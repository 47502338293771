import axios from "@/plugins/axios";
import { storeErrorHandler } from "@/utils/utils";
import { serialize } from "object-to-formdata";
import config from "@/config/config";
import eventBus from "@/utils/eventBus";

// State
const state = {
  loading: {
    fetch: false,
    create: false,
    update: null,
    delete: null,
    deleteAttachment: null,
    fetchLikes: null,
    toggleLike: null,
  },
};

// Getters
const getters = {
  loading: (state) => state.loading,
};

// Mutations
const mutations = {
  SET_LOADING: (state, { key, value }) => (state.loading[key] = value),
};

// Actions
const actions = {
  async fetch({ commit }, params = {}) {
    try {
      commit("SET_LOADING", { key: "fetch", value: true });

      const { data } = await axios.get("post-comments", { params });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetch", value: false });
    }
  },

  async create({ commit }, payload) {
    try {
      commit("SET_LOADING", { key: "create", value: true });

      const { data } = await axios.post("post-comments", serialize(payload));

      const comment = data.data;
      comment.fresh = true;

      eventBus.$emit("comment:created", comment);

      return comment;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "create", value: false });
    }
  },

  async update({ commit }, { id, payload }) {
    try {
      commit("SET_LOADING", { key: "update", value: id });

      payload._method = "PUT";
      const { data } = await axios.post(
        "post-comments/" + id,
        serialize(payload)
      );

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "update", value: null });
    }
  },

  async delete({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "delete", value: id });

      const { data } = await axios.delete("post-comments/" + id);

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "delete", value: null });
    }
  },

  async deleteAttachment({ commit }, { id, attachmentId }) {
    try {
      commit("SET_LOADING", { key: "deleteAttachment", value: attachmentId });

      const { data } = await axios.delete(
        `post-comments/${id}/attachments/${attachmentId}`
      );

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "deleteAttachment", value: null });
    }
  },

  async fetchLikes({ commit }, params = {}) {
    try {
      commit("SET_LOADING", {
        key: "fetchLikes",
        value: params["exact_search[comment_id]"],
      });

      params.per_page = config.likes.likesPerPage;

      const { data } = await axios.get("comment-likes", { params });
      data.data = data.data.map((i) => i.user);

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchLikes", value: null });
    }
  },

  async toggleLike({ commit }, id) {
    try {
      commit("SET_LOADING", { key: "toggleLike", value: id });

      const { data } = await axios.post("comment-likes", { comment_id: id });

      return data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "toggleLike", value: null });
    }
  },
};

// Export
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
