import axios from "@/plugins/axios";
import { storeErrorHandler } from "@/utils/utils";

// State
const state = {
  loading: {
    balance: false,
    activity: false,
  },
};

// Getters
const getters = {
  loading: (state) => state.loading,
};

// Mutations
const mutations = {
  SET_LOADING: (state, { key, value }) => (state.loading[key] = value),
};

// Actions
const actions = {
  async balance({ commit }, params = {}) {
    try {
      commit("SET_LOADING", { key: "balance", value: true });

      const { data } = await axios.get("wallet/balance", { params });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "balance", value: false });
    }
  },

  async activity({ commit }, params = {}) {
    try {
      commit("SET_LOADING", { key: "activity", value: true });

      const { data } = await axios.get("wallet/activity", { params });

      return data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "activity", value: false });
    }
  },
};

// Export
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
