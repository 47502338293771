import axios from "@/plugins/axios";
import router from "@/router";
import eventBus from "@/utils/eventBus";
import { serialize } from "object-to-formdata";
import { storeErrorHandler, roleCheck, dayjs } from "@/utils/utils";
import { downloadFile } from "@/utils/helpers";

// State
const state = {
  user: null,
  settings: null,
  loading: {
    user: false,
    login: false,
    loginVerify: false,
    logout: false,
    register: false,
    registerOnboard: false,
    sendVerificationLink: false,
    forgotPassword: false,
    resetPassword: false,
    changePassword: false,
    verifyEmail: false,
    emailExists: false,
    usernameValid: false,
    updateUser: false,
    requestUserData: false,
    downloadUserData: false,
    updateAccountSettings: false,
  },
};

// Getters
const getters = {
  user: (state) => state.user,
  loading: (state) => state.loading,
};

// Mutations
const mutations = {
  SET_USER: (state, value) => {
    if (value) {
      value.isSuperAdmin = () => roleCheck(["ROLE_SUPER_ADMIN"], value);
      value.isAdmin = () =>
        roleCheck(["ROLE_ADMIN", "ROLE_SUPER_ADMIN"], value);
      value.blocked_users = value.blocked_users || [];
    }

    state.user = value;
  },
  SET_USER_SETTINGS: (state, value) => (state.user.account_settings = value),
  SET_LOADING: (state, { key, value }) => (state.loading[key] = value),
};

// Actions
const actions = {
  async user({ commit }) {
    const urlToken = new URLSearchParams(window.location.search).get(
      "api_token"
    );

    if (!localStorage.getItem("token") && !urlToken) {
      return;
    }

    try {
      commit("SET_LOADING", { key: "user", value: true });

      const res = await axios.get("me");
      const user = res.data.data;

      commit("SET_USER", user);
      eventBus.$emit("auth:authenticated", user);

      return user;
    } catch (err) {
      commit("SET_USER", null);
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "user", value: false });
    }
  },

  async login({ commit, dispatch }, payload) {
    try {
      commit("SET_LOADING", { key: "login", value: true });

      await axios.get("csrf-cookie");
      const res = await axios.post("auth/login", payload);

      if (res.data.access_token) {
        localStorage.setItem("token", res.data.access_token);
        await dispatch("user");
      }

      return res.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "login", value: false });
    }
  },

  async loginVerify({ commit, dispatch }, payload) {
    try {
      commit("SET_LOADING", { key: "loginVerify", value: true });

      const res = await axios.post("auth/login-verify", payload);

      if (res.data.access_token) {
        localStorage.setItem("token", res.data.access_token);
        await dispatch("user");
      }

      return res.data;
    } catch (err) {
      return Promise.reject(err?.response?.data || err);
    } finally {
      commit("SET_LOADING", { key: "loginVerify", value: false });
    }
  },

  async logout({ commit, state }) {
    try {
      commit("SET_LOADING", { key: "logout", value: true });
      await axios.post("auth/logout");
      eventBus.$emit("auth:logout", state.user);
      return true;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      localStorage.removeItem("token");
      commit("SET_USER", null);
      commit("SET_LOADING", { key: "logout", value: false });
      router.replace({ name: "auth.login" });
    }
  },

  async register({ commit }, data) {
    try {
      commit("SET_LOADING", { key: "register", value: true });

      const res = await axios.post("register", data);

      return res.data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "register", value: false });
    }
  },

  async registerOnboard({ commit }, { token, data }) {
    try {
      commit("SET_LOADING", { key: "registerOnboard", value: true });

      data._method = "PUT";
      const res = await axios.post(
        "register-second-step/" + token,
        serialize(data, {
          nullsAsUndefineds: true,
        })
      );

      return res.data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "registerOnboard", value: false });
    }
  },

  async sendVerificationLink({ commit }, email) {
    try {
      commit("SET_LOADING", { key: "sendVerificationLink", value: true });

      const res = await axios.get("auth/resend-verification-email/" + email);

      return res.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "sendVerificationLink", value: false });
    }
  },

  async forgotPassword({ commit }, data) {
    try {
      commit("SET_LOADING", { key: "forgotPassword", value: true });

      const res = await axios.post("forgot-password", data);

      return res.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "forgotPassword", value: false });
    }
  },

  async resetPassword({ commit }, data) {
    try {
      commit("SET_LOADING", { key: "resetPassword", value: true });

      const res = await axios.post("reset-password", data);

      return res.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "resetPassword", value: false });
    }
  },

  async changePassword({ commit }, data) {
    try {
      commit("SET_LOADING", { key: "changePassword", value: true });

      const res = await axios.post("change-password", data);

      return res.data;
    } catch (err) {
      return Promise.reject(err.response);
    } finally {
      commit("SET_LOADING", { key: "changePassword", value: false });
    }
  },

  async verifyEmail({ commit }, token) {
    try {
      commit("SET_LOADING", { key: "verifyEmail", value: true });

      const res = await axios.get("auth/verify-email/" + token);

      return res.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "verifyEmail", value: false });
    }
  },

  async emailExists({ commit }, email) {
    try {
      commit("SET_LOADING", { key: "emailExists", value: true });

      const res = await axios.get("email-exists", {
        params: { email },
      });

      return res.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "emailExists", value: false });
    }
  },

  async usernameValid({ commit }, username) {
    try {
      commit("SET_LOADING", { key: "usernameValid", value: true });

      const res = await axios.get("username-valid", {
        params: { username },
      });

      return res.data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "usernameValid", value: false });
    }
  },

  async updateUser({ commit, state }, payload) {
    try {
      commit("SET_LOADING", { key: "updateUser", value: true });

      payload._method = "PUT";
      const res = await axios.post(
        "users/" + state.user.id,
        serialize(payload)
      );
      commit("SET_USER", res.data.data);

      return res.data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "updateUser", value: false });
    }
  },

  async requestUserData({ commit }) {
    try {
      commit("SET_LOADING", { key: "requestUserData", value: true });

      const res = await axios.get("download-user-data");

      return res.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "requestUserData", value: false });
    }
  },

  async downloadUserData({ commit }, token) {
    try {
      commit("SET_LOADING", { key: "downloadUserData", value: true });

      const res = await axios.get(`download-user-data/${token}`, {
        responseType: "blob",
        headers: {
          "Content-Disposition": "attachment",
        },
      });

      const date = dayjs().format("YYYY-MM-DD");
      downloadFile(res.data, `payfoot_user_data-${date}.pdf`);

      return res.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "downloadUserData", value: false });
    }
  },

  async updateAccountSettings({ commit }, payload) {
    try {
      commit("SET_LOADING", { key: "updateAccountSettings", value: true });

      const res = await axios.put("my-account-settings", payload);
      const settings = res.data.data[0];

      commit("SET_USER_SETTINGS", settings);

      return settings;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "updateAccountSettings", value: false });
    }
  },

  async walletLogin({ commit, dispatch }, data) {
    try {
      commit("SET_LOADING", { key: "walletLogin", value: true });

      const res = await axios.post("wallet/login", data);

      if (res.data.data.access_token) {
        localStorage.setItem("token", res.data.data.access_token);
        await dispatch("user");
      }

      return res.data.data;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "walletLogin", value: false });
    }
  },

  async getSignMessage({ commit }, data) {
    try {
      commit("SET_LOADING", { key: "getSignMessage", value: true });

      const res = await axios.post("wallet/sign-message", data);

      return res.data.data.sign_message;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "getSignMessage", value: false });
    }
  },
};

// Export
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
