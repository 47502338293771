import axios from "@/plugins/axios";
import { storeErrorHandler } from "@/utils/utils";

// State
const state = {
  default: [],
  trending: [],
  loading: {
    fetch: false,
    fetchDefault: false,
    fetchTrending: false,
  },
};

// Getters
const getters = {
  default: (state) => state.default,
  trending: (state) => state.trending,
  loading: (state) => state.loading,
};

// Mutations
const mutations = {
  SET_DEFAULT: (state, value) => (state.default = value),
  SET_TRENDING: (state, value) => (state.trending = value),
  SET_LOADING: (state, { key, value }) => (state.loading[key] = value),
};

// Actions
const actions = {
  async fetch({ commit }, params) {
    try {
      commit("SET_LOADING", { key: "fetch", value: true });

      const { data } = await axios.get("hashtags", { params });

      return data;
    } catch (err) {
      return storeErrorHandler(err.response);
    } finally {
      commit("SET_LOADING", { key: "fetch", value: false });
    }
  },

  async fetchDefault({ commit, state }, force = false) {
    try {
      if (!force && state.default.length > 0) {
        return;
      }

      commit("SET_LOADING", { key: "fetchDefault", value: true });

      const res = await axios.get("registration-hashtags");
      commit(
        "SET_DEFAULT",
        res.data.data.map((i) => i.name)
      );

      return res;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchDefault", value: false });
    }
  },

  async fetchTrending({ commit, state }, force = false) {
    try {
      if (!force && state.trending.length > 0) {
        return;
      }

      commit("SET_LOADING", { key: "fetchTrending", value: true });

      const res = await axios.get("trending-hashtags/" + 4);
      commit("SET_TRENDING", res.data.data);

      return res;
    } catch (err) {
      return storeErrorHandler(err);
    } finally {
      commit("SET_LOADING", { key: "fetchTrending", value: false });
    }
  },
};

// Export
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
