import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import eventBus from "@/utils/eventBus";
import setLocale from "@/utils/setLocale";
import { env } from "@/utils/utils";
import mixins from "@/mixins/global";
import "./plugins/app";
import "./plugins/larasocket";
import "./plugins/toast";
import "./plugins/v-img-viewer";
import "./plugins/v-mask";
import "./plugins/vue-meta";

Vue.config.productionTip = false;

Vue.mixin(mixins);

store.dispatch("auth/user").finally(() => {
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");

  if (env("ENV") === "maint") {
    return router.replace({ name: "maint" });
  }
});

eventBus.$on("auth:authenticated", (user) => {
  store.dispatch("chat/checkUnread");
  setLocale(user.account_settings?.locale || "en");
  setInterval(() => {
    store.dispatch("app/ping");
  }, 60000);
});
