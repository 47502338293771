import store from "@/store";

export default (roles) =>
  function ({ next }) {
    const user = store.getters["auth/user"];

    if (!user) {
      return next({
        name: "notFound",
        replace: true,
      });
    }

    if (typeof roles === "string") {
      roles = [roles];
    }

    for (const role of roles) {
      if (user.roles.includes(role)) {
        return next();
      }
    }

    return next({
      name: "notFound",
      replace: true,
    });
  };
