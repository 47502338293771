<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
import config from "@/config/config";

// Styles
import "@/styles/overrides.scss";
import "@/styles/helpers.scss";
import "@/styles/app.scss";

export default {
  name: "App",

  metaInfo: {
    title: "App",
    ...config.metaInfo,
  },
};
</script>
