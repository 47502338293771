import guestMiddleware from "../middleware/guest";

export default {
  path: "/auth",
  meta: {
    middleware: [guestMiddleware],
  },
  component: () =>
    import(
      /* webpackChunkName: "AuthLayout" */
      "@/components/layouts/auth/AuthLayout"
    ),
  children: [
    {
      path: "login",
      name: "auth.login",
      component: () =>
        import(
          /* webpackChunkName: "LoginPage" */
          "@/pages/auth/LoginPage"
        ),
    },
    {
      path: "register",
      name: "auth.register",
      component: () =>
        import(
          /* webpackChunkName: "RegisterPage" */
          "@/pages/auth/RegisterPage"
        ),
    },
    {
      path: "register/onboard/:token",
      name: "auth.register.onboard",
      component: () =>
        import(
          /* webpackChunkName: "RegisterOnboardPage" */
          "@/pages/auth/RegisterOnboardPage"
        ),
    },
    {
      path: "verify-email/:token",
      name: "auth.verify-email",
      component: () =>
        import(
          /* webpackChunkName: "VerifyEmailPage" */
          "@/pages/auth/VerifyEmailPage"
        ),
    },
    {
      path: "forgot-password",
      name: "auth.forgot-password",
      component: () =>
        import(
          /* webpackChunkName: "ForgotPasswordPage" */
          "@/pages/auth/ForgotPasswordPage"
        ),
    },
    {
      path: "reset-password/:email",
      name: "auth.reset-password",
      component: () =>
        import(
          /* webpackChunkName: "ResetPasswordPage" */
          "@/pages/auth/ResetPasswordPage"
        ),
    },
  ],
};
