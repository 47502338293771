<template>
  <time
    :datetime="formattedDate"
    :title="$utils.formatDatetime(value)"
    :key="dateKey"
  >
    {{ formattedDate }}</time
  >
</template>

<script>
export default {
  name: "Datetime",

  props: {
    value: {
      type: String,
      required: true,
    },
    format: String,
    date: Boolean,
    datetime: Boolean,
    time: Boolean,
    fromNow: Boolean,
  },

  data: () => ({
    dateKey: new Date().getTime(),
    dateKeyInterval: null,
    formattedDate: "",
  }),

  watch: {
    value() {
      this.setFormattedDate();
    },
  },

  mounted() {
    this.setFormattedDate();

    const age = this.checkAge();

    if (age !== 0 && this.fromNow) {
      const self = this;
      this.dateKeyInterval = setInterval(() => {
        self.dateKey = new Date().getTime();
        this.setFormattedDate();
      }, age);
    }
  },

  methods: {
    setFormattedDate() {
      if (this.date) {
        this.formattedDate = this.$utils.formatDate(this.value);
      } else if (this.datetime) {
        this.formattedDate = this.$utils.formatDatetime(this.value);
      } else if (this.time) {
        const now = new Date(this.value);
        this.formattedDate = now.getHours() + ":" + now.getMinutes();
      } else if (this.fromNow) {
        this.formattedDate = this.$utils.dayjs(this.value).fromNow();
      } else if (this.format) {
        this.formattedDate = this.$utils.dayjs(this.value).format(this.format);
      } else {
        this.formattedDate = this.$utils.formatDatetime(this.value);
      }
    },
    checkAge() {
      var age = (new Date() - new Date(this.value)) / 1000;
      // if newer
      if (age < 3600) {
        return 30000;
      } else {
        return 0;
      }
    },
  },

  beforeDestroy() {
    clearInterval(this.dateKeyInterval);
  },
};
</script>

<style></style>
